import React from 'react';
import { toFieldPath } from '@stackbit/annotations';
import { FluidText } from 'src/components/common/FluidText';
import { FeatureBlock, FeatureBlockCopy } from 'src/components/subsections/FeatureBlock';
import { FeatureBlockStatsList } from 'src/components/common/FeatureBlockStatsList';
import { FeatureBlockDetailsList } from 'src/components/common/FeatureBlockDetailsList';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { MARKDOWN_MODES } from 'src/tapestry/core/markdown';
import { cta_, alignNote_ } from './ContentfulKeyPointsVertical.module.scss';
import { ContentfulCTA } from '../../common/ContentfulCTA';
import { NoteCard } from '../../common/NoteCard/NoteCard.component';

const CUSTOM_GRID_PRESET_OVERRIDE = [
  // Heading area
  {
    md: {
      width: 5,
    },
  },
  // List area, spanning two rows
  {
    md: {
      width: 6,
      left: 7,
      height: 2,
    },
  },
  // Note card area
  {
    md: {
      width: 5,
    },
  },
];

export const ContentfulKeyPointsVertical = ({
  sectionHeading,
  cta,
  list,
  layout,
  sectionNote,
  ...subsectionProps
}) => {
  const ListComponent = layout === 'stats' ? FeatureBlockStatsList : FeatureBlockDetailsList;
  const { heading } = sectionHeading;
  const { getStringWithGlobals } = useTranslationContext();

  const listWithGlobals = list?.map(({ heading: listHeading, details, preHeading, ...rest }) => ({
    heading: getStringWithGlobals(listHeading),
    details: getStringWithGlobals(details),
    preHeading: getStringWithGlobals(preHeading),
    ...rest,
  }));

  return (
    <FeatureBlock
      {...subsectionProps}
      reverseWaterfallAnimation={false}
      presetOverride={sectionNote ? CUSTOM_GRID_PRESET_OVERRIDE : 'twoCellAlt'}
    >
      <FeatureBlockCopy>
        <FluidText type="h2" {...toFieldPath('sectionHeading')}>
          {getStringWithGlobals(heading)}
        </FluidText>
        {cta && <ContentfulCTA className={cta_} variant="secondary" size="xl" {...cta} />}
      </FeatureBlockCopy>
      <FeatureBlockCopy>
        {list && list.length > 0 && <ListComponent list={listWithGlobals} />}
      </FeatureBlockCopy>
      {sectionNote && (
        <FeatureBlockCopy className={alignNote_}>
          <NoteCard
            heading={sectionNote.heading && (
              <FluidText type="h3" all="ws-eyebrow-xl" {...toFieldPath('sectionNoteHeading')}>
                {getStringWithGlobals(sectionNote.heading)}
              </FluidText>
            )}
            body={sectionNote.body && (
              <FluidText
                type="markdown"
                min="ws-text-lg"
                max="ws-text-2xl"
                markdownProps={{
                  mode: MARKDOWN_MODES.inlineText,
                }}
                {...toFieldPath('sectionNoteBody')}
              >
                {getStringWithGlobals(sectionNote.body)}
              </FluidText>
            )}
          />
        </FeatureBlockCopy>
      )}
    </FeatureBlock>
  );
};
