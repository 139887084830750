import { colorNavyBg, colorMidnightBg, colorLinenBg, colorLinenLightBg,
  colorGrassLight, colorGrassBase, colorChartreuse95, colorCeleryBg90,
  colorSandLight, colorSandBase, colorBlushLight, colorBlushBase,
  colorOceanLight, colorOceanBase, colorTerracotta95, colorTerracottaBg90,
  colorApricot95, colorApricotBg90, colorAubergine95, colorAubergineBg90,
  colorLilac95, colorLilacBg90, colorSky95, colorSkyBg90,
  colorChartreuseBg90, colorSlate95, colorCelery95, colorBlack } from 'src/styles/exports.module.scss';
import blueSmileyCoin from './coins/blue-smiley-coin.webp';
import blueWealthsinmpleCoin from './coins/blue-wealthsimple-coin.webp';
import copperSmileyCoin from './coins/copper-smiley-coin.webp';
import goldMoneyCoin from './coins/gold-money-coin.webp';
import greenSmileyCoin from './coins/green-smiley-coin.webp';
import greenWealthsimpleCoin from './coins/green-wealthsimple-coin.webp';
import orangeSmileyCoin from './coins/orange-smiley-coin.webp';
import pewterWealthsimpleCoin from './coins/pewter-wealthsimple-coin.webp';
import pinkgoldWealthsimpleCoin from './coins/pinkgold-wealthsimple-coin.webp';
import silverWealthsimpleCoin from './coins/silver-wealthsimple-coin.webp';

export const HEX_COLOR_MAP = {
  [colorNavyBg]: {
    label: 'Generation/Navy',
    secondaryColor: colorMidnightBg,
    coinImage: pewterWealthsimpleCoin,
    ctaTheme: 'dark'
  },
  [colorMidnightBg]: {
    label: 'Generation/Midnight',
    secondaryColor: colorNavyBg,
    coinImage: pewterWealthsimpleCoin,
    ctaTheme: 'dark'
  },
  [colorLinenLightBg]: {
    label: 'Premium/LightLinen',
    secondaryColor: colorLinenBg,
    coinImage: pewterWealthsimpleCoin,
    ctaTheme: 'light'
  },
  [colorLinenBg]: {
    label: 'Premium/Linen',
    secondaryColor: colorLinenLightBg,
    coinImage: pewterWealthsimpleCoin,
    ctaTheme: 'light'
  },
  [colorGrassLight]: {
    label: 'Grass/Light',
    secondaryColor: colorChartreuse95,
    coinImage: greenSmileyCoin,
    ctaTheme: 'light'
  },
  [colorGrassBase]: {
    label: 'Grass/Medium',
    secondaryColor: colorCeleryBg90,
    coinImage: greenSmileyCoin,
    ctaTheme: 'light'
  },
  [colorSandLight]: {
    label: 'Sand/Light',
    secondaryColor: colorLinenBg,
    coinImage: goldMoneyCoin,
    ctaTheme: 'light'
  },
  [colorSandBase]: {
    label: 'Sand/Medium',
    secondaryColor: colorLinenBg,
    coinImage: goldMoneyCoin,
    ctaTheme: 'light'
  },
  [colorBlushLight]: {
    label: 'Blush/Light',
    secondaryColor: colorBlushBase,
    coinImage: goldMoneyCoin,
    ctaTheme: 'light'
  },
  [colorBlushBase]: {
    label: 'Blush/Medium',
    secondaryColor: colorBlushLight,
    coinImage: goldMoneyCoin,
    ctaTheme: 'light'
  },
  [colorOceanLight]: {
    label: 'Ocean/Light',
    secondaryColor: colorSky95,
    coinImage: blueWealthsinmpleCoin,
    ctaTheme: 'light'
  },
  [colorOceanBase]: {
    label: 'Ocean/Medium',
    secondaryColor: colorOceanLight,
    coinImage: blueWealthsinmpleCoin,
    ctaTheme: 'light'
  },
  [colorTerracotta95]: {
    label: 'Terracotta/Foreground/95',
    secondaryColor: colorTerracottaBg90,
    coinImage: copperSmileyCoin,
    ctaTheme: 'light'
  },
  [colorTerracottaBg90]: {
    label: 'Terracotta/Background/90',
    secondaryColor: colorTerracotta95,
    coinImage: copperSmileyCoin,
    ctaTheme: 'light'
  },
  [colorApricot95]: {
    label: 'Apricot/Foreground/95',
    secondaryColor: colorApricotBg90,
    coinImage: orangeSmileyCoin,
    ctaTheme: 'light'
  },
  [colorApricotBg90]: {
    label: 'Apricot/Background/90',
    secondaryColor: colorApricot95,
    coinImage: orangeSmileyCoin,
    ctaTheme: 'light'
  },
  [colorAubergine95]: {
    label: 'Aubergine/Foreground/95',
    secondaryColor: colorAubergineBg90,
    coinImage: pinkgoldWealthsimpleCoin,
    ctaTheme: 'light'
  },
  [colorAubergineBg90]: {
    label: 'Aubergine/Background/90',
    secondaryColor: colorAubergine95,
    coinImage: pinkgoldWealthsimpleCoin,
    ctaTheme: 'light'
  },
  [colorLilac95]: {
    label: 'Lilac/Foreground/95',
    secondaryColor: colorLilacBg90,
    coinImage: pinkgoldWealthsimpleCoin,
    ctaTheme: 'light'
  },
  [colorLilacBg90]: {
    label: 'Lilac/Background/90',
    secondaryColor: colorLilac95,
    coinImage: pinkgoldWealthsimpleCoin,
    ctaTheme: 'light'
  },
  [colorSky95]: {
    label: 'Sky/Foreground/95',
    secondaryColor: colorSkyBg90,
    coinImage: blueSmileyCoin,
    ctaTheme: 'light'
  },
  [colorSkyBg90]: {
    label: 'Sky/Background/90',
    secondaryColor: colorSky95,
    coinImage: blueSmileyCoin,
    ctaTheme: 'light'
  },
  [colorChartreuse95]: {
    label: 'Chartreuse/Foreground/95',
    secondaryColor: colorChartreuseBg90,
    coinImage: greenWealthsimpleCoin,
    ctaTheme: 'light'
  },
  [colorChartreuseBg90]: {
    label: 'Chartreuse/Background/90',
    secondaryColor: colorCelery95,
    coinImage: greenWealthsimpleCoin,
    ctaTheme: 'light'
  },
  [colorSlate95]: {
    label: 'Slate/95',
    secondaryColor: colorLinenLightBg,
    coinImage: silverWealthsimpleCoin,
    ctaTheme: 'light'
  },
  [colorBlack]: {
    label: 'SoftBlack',
    value: '#32302F',
    secondaryColor: colorMidnightBg,
    coinImage: silverWealthsimpleCoin,
    ctaTheme: 'dark'
  },
};

export const getColorInfo = (color, colorMap = HEX_COLOR_MAP) => {
  if (typeof color === 'string') { return colorMap[color.toLowerCase()] ?? {}; }
  return {};
};
