import { DEFAULT_LOCALE } from 'src/utils/localization';
import { withFramework } from 'lib/get-framework';
import { BodyContent } from './BodyContent.component';
import { willComponentRenderInLocale } from './withLocaleCheckboxes';
import { SPECIAL_CONTENT_SELECTIONS } from './ContentfulSpecialContent';
import { CONTENTFUL_SECTIONS } from './contentful.constants';

const NEXTJS_CONTENT_TYPE_TO_LEGACY_CONTENT_TYPE_ID = {
  ImageWithText: CONTENTFUL_SECTIONS.MEDIA_WITH_DESCRIPTION,
  KeyPointsVertical: CONTENTFUL_SECTIONS.KEY_POINTS_VERTICAL,
  KeyStats: CONTENTFUL_SECTIONS.KEY_STATS,
  PromotionalSection: CONTENTFUL_SECTIONS.PROMOTIONAL,
  SectionFeatureCard: CONTENTFUL_SECTIONS.FEATURE_CARD,
  SectionSpecialContent: CONTENTFUL_SECTIONS.SPECIAL_CONTENT,
  TableSection: CONTENTFUL_SECTIONS.TABLE,
  TierCardsSection: CONTENTFUL_SECTIONS.TIER_CARDS,
};

/**
 * @deprecated Gatsby only; use getContentTypeId instead
 *
 * Safely gets the content type ID from a section object.
 * @param {Object} section - The section object.
 * @returns {string|null} - The content type ID or null if not found.
 */
export const safeGetContentTypeId = section => (
  section?.sys?.contentType?.sys?.id ?? null
);

export const getContentTypeId = section => {
  return NEXTJS_CONTENT_TYPE_TO_LEGACY_CONTENT_TYPE_ID[section.__typename] || section.__typename || null;
};

/**
 * Safely gets the value of a field from a Contentful object.
 * Helpful for Markdown fields which nest the value in Object.value.value
 * @param {string} fieldName - The name of the field.
 * @param {Object} contentfulObject - The Contentful object.
 * @returns {*} - The value of the field or null if not found.
 */
export const safeGetContentfulFieldValue = (fieldName, contentfulObject) => {
  const initialValue = contentfulObject?.[fieldName] || null;
  if (typeof initialValue === 'object') {
    return initialValue?.[fieldName] ?? initialValue;
  }
  return initialValue;
};

/**
 * Creates a custom body section object that can be rendered in the data.page.bodySections array passed to <ContentfulPageLayout>
 * @param {string} contentfulId - The Contentful ID for the section - should be the Contentful ID of the CMS entry that the component data belongs to
 * @param {function} component - The React component to render.
 * @param {string} componentName - The name of the component - useful when you want to apply a background color to the section via custom config rules
 * @param {...*} props - Any props to pass to the component when it is rendered
 * @returns {Object} - The custom body section object, renderable by ContentfulPageLayout
 * @throws {Error} - If `component` or `contentfulId` is missing, or if `component` is not a function.
 */
export const createCustomBodySection = ({ contentfulId, component, componentName, ...props }) => {
  if (!component || !contentfulId) {
    throw new Error('`createComponentProps` requires `contentfulId` and `component` props');
  }
  if (typeof component !== 'function') {
    throw new Error('`component` must be a function which renders a valid React component');
  }

  return withFramework({
    gatsby: () => ({
      ...props,
      contentfulId,
      id: contentfulId, // We use this id to apply background color and in the section iterator
      component,
      sys: {
        contentType: {
          sys: {
            id: componentName ?? 'customComponent',
          }
        }
      }
    }),
    next: () => ({
      ...props,
      sys: {
        id: contentfulId
      },
      id: contentfulId, // We use this id to apply background color and in the section iterator
      component,
      __typename: componentName ?? 'customComponent',
    })
  });
};

/**
 * Overrides a custom section in the pageSections array with the provided customSection.
 * @param {Object} customSection - The new custom section object to replace the existing section, usually created by createCustomBodySection
 * @param {function} sectionIdentificationCallback - A callback function that is called on a section object and returns true when the section is the one to be overridden.
 * @param {Array} pageSections - The array of page sections to override.
 * @returns {Object} - An object containing the updated pageSections array with the custom section replaced.
 */
export const overridePageSection = (customSection, sectionIdentificationCallback, pageSections = []) => {
  if (pageSections && typeof sectionIdentificationCallback === 'function') {
    const sectionIndex = pageSections.findIndex(sectionIdentificationCallback);
    if (sectionIndex === -1) {
      return pageSections;
    }
    return [...pageSections.slice(0, sectionIndex), customSection, ...pageSections.slice(sectionIndex + 1)];
  }
  return pageSections;
};

const DEFAULT_BODY_SECTION_CONFIG = {
  backgroundColor: null,
  currentLocale: DEFAULT_LOCALE,
  data: {},
};

const HAS_OWN_SECTION = [
  CONTENTFUL_SECTIONS.FEATURE_CARD
];

/*
  * Takes raw body content from Contentful and prepares it for rendering
*/
export const prepareBodySections = (content, config = {}) => {
  if (!content) {
    return [];
  }

  const _content = withFramework({
    gatsby: () => content,
    next: () => content.items,
  });

  const resolvedConfig = {
    ...DEFAULT_BODY_SECTION_CONFIG,
    ...config
  };

  return _content.reduce((acc, { id, sys, backgroundColor, sectionProps = {}, ...item }, index) => {
    const prevItem = _content[index - 1];
    const isLastItem = index === _content.length - 1;
    const hasHeading = !!item.sectionHeading;
    const hasLocalesList = Object.prototype.hasOwnProperty.call(item, 'locales');
    const hasBackgroundColor = !!backgroundColor;
    const contentTypeId = withFramework({
      gatsby: () => safeGetContentTypeId({ sys }),
      next: () => getContentTypeId(item),
    });

    const requiresTopSpacing = index === 0 ||
      (!hasBackgroundColor && prevItem?.backgroundColor) ||
        (hasBackgroundColor && (backgroundColor !== prevItem.backgroundColor));

    const contentfulId = withFramework({
      gatsby: () => item.contentfulId,
      next: () => sys.id,
    });
    // eslint-disable-next-line no-unused-vars
    const { contentfulId: _, component: CustomComponent, ...restItem } = item;
    // If we have no contentTypeId, we cannot match this item to a component to render
    // If this item does not match the current locale, we should not render it
    if (!contentTypeId || (hasLocalesList && !willComponentRenderInLocale({ currentLocale: resolvedConfig.currentLocale, localesList: item.locales }))) {
      return acc;
    }

    // Some content needs data queried from the page-level; right now this is limited to one section but could be expanded
    const extraItemData = item?.specialContentSelection === SPECIAL_CONTENT_SELECTIONS.FILTERABLE_ACCOUNT_CARDS
      ? { accountDetails: resolvedConfig.data.accountDetails } : {};

    // Create component object
    const componentProps = CustomComponent ? { component: CustomComponent, props: { ...restItem, entryId: contentfulId } }
      : { component: BodyContent, props: { contentProps: { ...restItem, ...extraItemData, contentTypeId }, entryId: contentfulId } };

    // If we don't have a section heading, we should push this content into the previous section
    if (!HAS_OWN_SECTION.includes(contentTypeId) && !CustomComponent && !hasHeading && acc.length > 0) {
      acc[acc.length - 1].components.push(componentProps);
      return acc;
    }

    return [
      ...acc,
      {
        id: withFramework({ gatsby: () => id, next: () => contentfulId }),
        components: [componentProps],
        sectionProps: {
          ...(hasBackgroundColor ? { background: backgroundColor } : {}),
          ...(isLastItem && (process.env.NEXT_PUBLIC_CMS_PREVIEW !== '1') ? { animation: 'squeeze' } : {}), // the squeeze breaks some editablity
          ...(!requiresTopSpacing ? { verticalSpacingCollapse: 'top' } : {}),
          ...sectionProps
        },
      },
    ];
  }, []);
};
