import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { classNames } from 'src/utils/css';
import { useMotionOptOutContext } from 'src/components/contexts/MotionOptOutContext';
import { asSubsection } from 'src/components/layout/Subsection';
import { useComponentContext } from 'src/components/layout/ComponentContext';
import { FluidText } from 'src/components/common/FluidText';
import { Card, cardPropTypes } from 'src/components/common/Card';
import { toFieldPath } from '@stackbit/annotations';
import { toXPathData } from 'src/utils/netlify-create/xpath';
import {
  textWrapper_,
  textContainer_,
  cardsWrapper_,
  cardsScrollingContainer_,
  card_,
  squeezeCard_,
  expandCard_,
  subheadingSpacing_,
} from './HorizontalScrollingCards.module.scss';

const waterfallAnimationDelay = 0.12;
const HorizontalScrollingCards = asSubsection('HorizontalScrollingCards')(
  ({ heading = '', subheading = '', cards = [], reverseWaterfallAnimation = false, animationDelay = 0, ...restProps }) => {
    const shouldExpandCards = cards.length < 3;
    const shouldSqueezeCards = cards.length > 3;
    const [viewRef, inView] = useInView({ triggerOnce: true, threshold: 0.5 });
    const { prefersReducedMotion } = useMotionOptOutContext();

    const subheadingDelay = heading ? waterfallAnimationDelay : animationDelay;
    const cardsDelay = subheadingDelay + waterfallAnimationDelay;

    const { entryId } = useComponentContext();
    const headingAnnotation = entryId ? { ...toFieldPath('learnMoreHeading') } : {};
    const subheadingAnnotation = entryId ? { ...toFieldPath('learnMoreBody') } : {};

    return (
      <div
        {...restProps}
        ref={viewRef}
        className={classNames(
          inView && 'waterfall-trigger',
          reverseWaterfallAnimation && !prefersReducedMotion && 'waterfall-transition'
        )}
      >
        {(heading || subheading) && (
          <div className={textWrapper_}>
            <div className={textContainer_}>
              {heading && (
                <FluidText
                  className="waterfall-element"
                  type="h2"
                  style={{ transitionDelay: `${animationDelay}s` }}
                  {...headingAnnotation}
                >
                  {heading}
                </FluidText>
              )}
              {subheading && (
                <FluidText
                  className={classNames('waterfall-element', subheadingSpacing_)}
                  style={{ transitionDelay: `${subheadingDelay}s` }}
                  type="markdown"
                  {...subheadingAnnotation}
                >
                  {subheading}
                </FluidText>
              )}
            </div>
          </div>
        )}
        <div
          className={classNames(cardsWrapper_, 'waterfall-element')}
          style={{ transitionDelay: `${cardsDelay}s` }}
        >
          <div className={cardsScrollingContainer_}>
            {cards.map(({ bottomCopy, id, contentfulId: entryId_, ...props }) => {
              return (<Card
                key={id || props.heading}
                className={classNames(
                  card_,
                  shouldSqueezeCards && squeezeCard_,
                  shouldExpandCards && expandCard_
                )}
                footerContent={
                  bottomCopy && (
                    <FluidText type="p" min="ws-text-lg" max="ws-text-xl" {...toXPathData('HorizontalScrollingCards_footerText')}>
                      {bottomCopy}
                    </FluidText>
                  )
                }
                entryId={entryId_}
                {...props}
              />);
            })}
          </div>
        </div>
      </div>
    );
  }
);

HorizontalScrollingCards.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      ...cardPropTypes,
      bottomCopy: PropTypes.string,
    })
  ).isRequired,
  reverseWaterfallAnimation: PropTypes.bool,
  animationDelay: PropTypes.number,
};

HorizontalScrollingCards.displayName = 'HorizontalScrollingCards';

export { HorizontalScrollingCards };
