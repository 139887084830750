'use client';

import React, { forwardRef, useEffect, useCallback, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import { classNames } from 'src/utils/css';
import { FluidText } from 'src/components/common/FluidText';
import { wrapper_, tabSelector_, buttonBg_, track_, tabSelectorSmall_, trackSmall_, tabSelectorLarge_, trackLarge_, fullWidth_ } from './TabSelector.module.scss';
import { FormFieldLabel } from '../FormFieldLabel';

const TabSelector = forwardRef(function TabSelector({ tabs = ['one', 'two', 'three', 'four'], size = 'sm', fullWidth = false, initialTab, value, onChange, className, label, message, tooltipDescription, ...rest }, ref) {
  const initialTabId = tabs.find(tab => tab === initialTab) ?? tabs[0];
  const containerRef = useRef();
  const buttonBgRef = useRef();
  const trackRef = useRef();
  const tabStatusRef = useRef({
    active: initialTabId,
    lastActive: initialTabId,
  });

  const SIZES = {
    sm: {
      fluidTextConfig: { min: 'ws-text-sm-medium', max: 'ws-text-md-medium' },
      wrapperClass: tabSelectorSmall_,
      trackClass: trackSmall_,
    },
    lg: {
      fluidTextConfig: { min: 'ws-text-sm-medium', max: 'ws-text-xl-medium' },
      wrapperClass: tabSelectorLarge_,
      trackClass: trackLarge_,
    }
  };

  const updateButtonBgPosition = ({ width, x }) => {
    buttonBgRef.current.style = `width: ${Math.round(width)}px; translate: ${Math.round(x)}px;`;
  };

  const animate = () => {
    const current = containerRef.current.querySelector(
      `[data-tab-id="${tabStatusRef.current.active}"]`
    );
    const last = containerRef.current.querySelector(
      `[data-tab-id="${tabStatusRef.current.lastActive}"]`
    );

    // Triggers text color change
    last.dataset.active = false;
    current.dataset.active = true;

    // Animate button background element width to match active button dimensions.
    const { width } = current.getBoundingClientRect();
    const x = current.offsetLeft;
    updateButtonBgPosition({ width, x });
  };

  const updateActiveTab = useCallback(tabId => {
    if (tabStatusRef.current.active !== tabId) {
      tabStatusRef.current.lastActive = tabStatusRef.current.active;
      tabStatusRef.current.active = tabId;
      if (onChange) onChange(tabId);
      animate();
    }
  }, [onChange]);

  const handleClick = e => {
    const targetEl = e.currentTarget;
    const tabId = targetEl?.dataset?.tabId;
    updateActiveTab(tabId);
  };

  useEffect(() => {
    if (!value) return;

    const tabIndex = tabs.findIndex(tab => tab === value);
    const tabId = tabs[tabIndex];
    updateActiveTab(tabId);
  }, [tabs, updateActiveTab, value]);

  useLayoutEffect(() => {
    const handleResize = () => { animate(); };
    const throttledResize = throttle(handleResize, 200);
    window.addEventListener('resize', throttledResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', throttledResize);
    };
  }, []);

  return (
    <div ref={ref} className={wrapper_}>
      {label && (
        <FormFieldLabel label={label} message={message} tooltipDescription={tooltipDescription} />
      )}
      <div ref={containerRef} className={classNames(tabSelector_, SIZES[size].wrapperClass, fullWidth && fullWidth_, className)} {...rest}>
        <div ref={trackRef} className={classNames(track_, SIZES[size].trackClass)}>
          <div ref={buttonBgRef} className={buttonBg_} />
          {tabs.map(tabName => {
            return (
              <button key={tabName} onClick={handleClick} data-tab-id={tabName} data-active={tabName === tabStatusRef.current.active} type="button">
                <FluidText type="span" {...SIZES[size].fluidTextConfig}>{tabName}</FluidText>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
});

TabSelector.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string.isRequired),
  initialTab: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'lg']),
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  message: PropTypes.string,
};

export { TabSelector };
