import React from 'react';
import { withFramework } from 'lib/get-framework';
import { SectionHeading } from 'src/components/subsections/SectionHeading';
import { useTranslation, useTranslationContext } from 'src/components/contexts/TranslationContext';
import { GRID_DEFAULT_CONFIG, RefreshCell, RefreshGrid } from 'src/components/layout/Grid';
import { Subsection } from 'src/components/layout/Subsection';
import { PricingCard } from 'src/components/common/PricingCards';
import { toFieldPath } from '@stackbit/annotations';
import { FluidText } from 'src/components/common/FluidText';
import { MARKDOWN_MODES } from 'src/tapestry/core/markdown';
import { translations } from './ContentfulTierCards.translations';
import { TIER_TYPE_TO_VARIANT_CONFIG } from './ContentfulTierCards.config';

const ContentfulTierCard = ({ tierType = 'Core', itemBody: _itemBody, featuredStatValue, featuredStatDescription, ...rest }) => {
  const { getStringWithGlobals } = useTranslationContext();
  const itemBody = withFramework({
    gatsby: () => _itemBody?.itemBody,
    next: () => _itemBody,
  });

  return (
    <PricingCard
      variant="pdp"
      tierId={TIER_TYPE_TO_VARIANT_CONFIG[tierType]?.tierId}
      // PDP Card Props
      eyebrow={useTranslation(`contentful-tier-cards::tier-card::${tierType}::eyebrow`)}
      heading={useTranslation(`contentful-tier-cards::tier-card::${tierType}::heading`)}
      subheading={useTranslation(`contentful-tier-cards::tier-card::${tierType}::body`)}
      fxRate={<span {...toFieldPath('featuredStatValue')}>{getStringWithGlobals(featuredStatValue)}</span>}
      fxText={<span {...toFieldPath('featuredStatDescription')}>{getStringWithGlobals(featuredStatDescription)}</span>}
      {...rest}
    >
      {itemBody && (
        <FluidText
          type="markdown"
          all="ws-text-lg"
          className="ws-unordered-list"
          markdownProps={{ mode: MARKDOWN_MODES.checklist }}
          {...toFieldPath('itemBody')}
        >
          {getStringWithGlobals(itemBody)}
        </FluidText>
      )}
    </PricingCard>
  );
};

export const ContentfulTierCards = ({
  sectionHeading = {},
  tierCards: _tierCards = [],
  ...subsectionProps
}) => {
  const { getStringWithGlobals, appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);

  const { heading } = sectionHeading;
  const tierCards = withFramework({
    gatsby: () => _tierCards,
    next: () => _tierCards.items,
  });

  return (
    <>
      {heading && (
        <SectionHeading
          {...subsectionProps}
          heading={getStringWithGlobals(heading)}
          textAlign="left"
          addBottomSpacing
        />)}
      {tierCards?.length > 0 && (
        <Subsection subsectionName="ContentfulTierCards" {...subsectionProps}>
          <RefreshGrid xs={{ columnGap: 0 }} md={{ columnGap: GRID_DEFAULT_CONFIG.gap }}>
            {tierCards.map(({
              contentfulId,
              tierType,
              itemBody,
              featuredStatValue,
              featuredStatDescription,
            }) => {
              return (
                <RefreshCell
                  key={contentfulId}
                  sm={{ width: 8, left: 3 }}
                  lg={{ width: 4, left: 'auto' }}
                >
                  <ContentfulTierCard
                    tierType={tierType}
                    itemBody={itemBody}
                    featuredStatValue={featuredStatValue}
                    featuredStatDescription={featuredStatDescription}
                    entryId={contentfulId}
                  />
                </RefreshCell>
              );
            })}
          </RefreshGrid>
        </Subsection>
      )}
      <div />
    </>
  );
};
