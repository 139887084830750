'use client';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { classNames } from 'src/utils/css';
import { List } from 'src/tapestry/core/list';
import { SmartLink } from 'src/tapestry/core/links/smart-link';
import { Background } from 'src/components/common/Background';
import { toXPathData } from 'src/utils/netlify-create/xpath';
import { colorWhite } from 'src/styles/exports.module.scss';
import { ArrowLinkButton } from '../ArrowLinkButton';
import { FluidText } from '../FluidText';
import { TagPill } from '../TagPill';
import {
  slate95_,
  wrapper_,
  content_,
  heading_,
  subheading_,
  list_,
  button_,
  footerContentWrapper_,
  footerContent_,
  buttonInline_,
  eyebrow_,
  headingSupport_,
  contentFullWidth_,
  withMediaContainer_,
  active_,
  mediaWrapper_,
} from './Card.module.scss';

export const Card = ({
  className,
  background,
  heading,
  headingSupport,
  pill,
  eyebrow,
  subheading,
  list,
  link,
  media,
  footerContent,
  contentFullWidth,
  children,
  ...rest
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  const headingSupportContent = isString(headingSupport) ? (
    <FluidText
      type="p"
      min="ws-text-lg-medium"
      max="ws-text-2xl-medium"
      className={headingSupport_}
    >
      {headingSupport}
    </FluidText>
  ) : (
    { headingSupport }
  );
  const eyebrowContent = isString(eyebrow) ? (
    <FluidText type="p" min="ws-eyebrow-sm" max="ws-eyebrow-md" className={eyebrow_}>
      {eyebrow}
    </FluidText>
  ) : (
    eyebrow
  );
  const subheadingContent = (
    <FluidText
      type={isString(subheading) ? 'p' : 'div'}
      min="ws-text-lg"
      max="ws-text-xl"
      className={subheading_}
      {...toXPathData('Card_subheading')}
    >
      {subheading}
    </FluidText>
  );
  const pillContent = isString(pill) ? (
    <TagPill variant="xl" background={slate95_}>
      {pill}
    </TagPill>
  ) : (
    pill
  );

  const cardBodyContent = (
    <>
      <div className={classNames(content_, contentFullWidth && contentFullWidth_)}>
        {eyebrow && eyebrowContent}
        {heading && (
          <FluidText className={heading_} type="h3" all="ws-display-md-sans">
            <span {...toXPathData('Card_heading')}>{heading}</span>
            {pill && pillContent}
          </FluidText>
        )}
        {headingSupport && headingSupportContent}
        {subheading && subheadingContent}
        {list && <List className={list_} content={list} bodySize="lg" />}
        {footerContent && (
          <div className={footerContentWrapper_}>
            <div className={footerContent_}>{footerContent}</div>
          </div>
        )}
        {children}
      </div>
      {media && <div className={mediaWrapper_}>{media}</div>}
    </>
  );

  return (
    <Background
      className={classNames(wrapper_, isMouseOver && active_, className)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      href={link}
      as={SmartLink}
      background={background || colorWhite}
      {...rest}
    >
      {media ? (
        <div className={classNames(withMediaContainer_)}>{cardBodyContent}</div>
      ) : (
        cardBodyContent
      )}
      {link && (
        <div className={classNames(button_, footerContent && buttonInline_)}>
          <ArrowLinkButton isActive={isMouseOver} />
        </div>
      )}
    </Background>
  );
};

export const cardPropTypes = {
  className: PropTypes.string,
  background: PropTypes.string,
  heading: PropTypes.string,
  pill: PropTypes.node,
  subheading: PropTypes.node,
  list: PropTypes.arrayOf(PropTypes.string),
  link: PropTypes.string,
  footerContent: PropTypes.node,
  children: PropTypes.node,
  contentFullWidth: PropTypes.bool,
  media: PropTypes.node,
  eyebrow: PropTypes.node,
  headingSupport: PropTypes.string,
};

Card.propTypes = cardPropTypes;
