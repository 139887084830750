'use client';

import React from 'react';
import PropTypes from 'prop-types';
import { toFieldPath, toObjectId } from '@stackbit/annotations';
import { colorSandBase } from 'src/styles/exports.module.scss';
import { withFramework } from 'lib/get-framework';
import { HorizontalScrollingCards } from 'src/components/subsections/HorizontalScrollingCards';
import { ContentfulCTA } from 'src/components/common/ContentfulCTA';
import { ContentfulNotificationBanner } from 'src/components/common/NotificationBanner';
import { ContentfulFinalCta } from 'src/components/contentful/ContentfulFinalCta.component';
import { CoverImage } from 'src/components/common/CoverImage';
import { ContentfulFaq } from 'src/components/contentful/ContentfulFaq.component';
import { ContentfulHero, HERO_VARIANTS } from 'src/components/contentful/ContentfulHero/ContentfulHero.component';
import { prepareBodySections } from 'src/components/contentful/contentful.helpers.js';
import { generateXPathSelectorString } from 'src/utils/netlify-create/xpath';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { Header } from '../Header';
import { PageContent } from '../PageContent';
import { PageLayout } from '../PageLayout';
import { Section } from '../Section';

// *** Best practices for Netlify Create annotations that I'm trying to nail down *** //
// 1. If an entry id (contentful_id) needs to be passed to a component, name the prop `entryId` and use `toObjectId` in that component so that we are never passing the prop as an already-converted object
// 2. Same as above ^ with `fieldPath`, which is less common
// 3. Design system components will only have annotations set on them directly if there is a consistent model associated with them, for example: FAQ items

const ContentfulPageLayout = ({
  pageContext = {},
  data,
  customPrimaryCta,
  customFinalCta,
  customColorTheme = {},
  children,
}) => {
  const { noIndex } = pageContext;
  const { page, accountDetails } = data;

  const contentful_id = withFramework({
    gatsby: () => page.contentful_id,
    next: () => page.sys.id,
  });

  const metadata = page?.pageMetadata;
  const {
    // Style
    style,
    // Nav
    navIncludeLinks = true,
    navIncludeLogin = true,
    navIncludeCta = true,
    // Banner
    notificationBanner,
    // CTA
    primaryCtaUrl,
    primaryCtaLabel,
    primaryCtaLabelMobile,
    // Hero
    heroEyebrow,
    heroHeading,
    heroBody,
    heroAsset,
    heroBackgroundColor,
    heroVariant,
    heroSecondaryCtaLabel,
    heroSecondaryCtaUrl,
    // Body content
    pageSections,
    // Final CTA
    finalCtaHeading,
    finalCtaBody,
    finalCtaBackgroundColor,
    finalCtaImage,
    // Learn more
    learnMoreHeading,
    learnMoreBody,
    learnMoreCards: _learnMoreCards,
    // FAQs
    faqHeading,
    faqQuestions,
  } = page || {};
  const { getStringWithGlobals, locale } = useTranslationContext();
  const { heroBackground: customHeroBackground } = customColorTheme;
  const learnMoreCards = withFramework({
    gatsby: () => _learnMoreCards,
    next: () => _learnMoreCards?.items,
  });

  const hasFinalCtaImage = !!finalCtaImage;
  const finalCtaImageAlignment = style?.finalCtaImage?.alignItems;

  const primaryCta = customPrimaryCta || (
    <ContentfulCTA
      type="link"
      link={primaryCtaUrl}
      label={primaryCtaLabel}
      mobileLabel={primaryCtaLabelMobile}
      labelFieldPath={toFieldPath({ objectId: contentful_id, fieldPath: 'primaryCtaLabel' })}
      {...toFieldPath({ objectId: contentful_id, fieldPath: 'primaryCtaUrl' })}
    />
  );

  const resolvedHeroBackgroundColor = heroBackgroundColor || customHeroBackground;
  const isStaticHeroVariant = heroVariant === HERO_VARIANTS.STATIC;

  const preparedBodyContent = prepareBodySections(pageSections, { currentLocale: locale,
    data: {
      accountDetails
    } });

  const hasBodyContent = preparedBodyContent?.length > 0;
  const hasLearnMoreSection = learnMoreCards?.length > 0;
  const hasFaqSection = withFramework({
    gatsby: () => faqQuestions?.length > 0,
    next: () => faqQuestions?.items.length > 0,
  });

  const hasNotificationBanner = withFramework({
    gatsby: () => notificationBanner?.body?.body,
    next: () => notificationBanner?.body
  });

  return (
    <PageLayout
      metadata={{ ...metadata, noIndex }}
      customHeader={
        <Header
          links={navIncludeLinks ? null : []}
          primaryCta={navIncludeCta ? primaryCta : {}}
          secondaryCta={navIncludeLogin ? null : {}} // null displays login button
        />
      }
      notificationBanner={
        (hasNotificationBanner) && (
          <ContentfulNotificationBanner {...notificationBanner} />
        )
      }
      entryId={contentful_id}
    >
      <PageContent>
        <Section
          verticalSpacingCollapse={hasBodyContent ? 'all' : 'top'}
          verticalSpacing={!hasBodyContent ? 'tight' : 'normal'}
          background={isStaticHeroVariant ? resolvedHeroBackgroundColor : null}
        >
          <ContentfulHero
            heroEyebrow={heroEyebrow}
            heroHeading={heroHeading}
            heroBody={withFramework({
              gatsby: () => heroBody?.heroBody,
              next: () => heroBody
            })}
            heroAsset={heroAsset}
            heroBackgroundColor={resolvedHeroBackgroundColor}
            primaryCta={primaryCta}
            heroSecondaryCtaLabel={heroSecondaryCtaLabel}
            heroSecondaryCtaUrl={heroSecondaryCtaUrl}
            contentful_id={contentful_id}
            heroVariant={heroVariant}
          />
        </Section>
        {hasBodyContent &&
          preparedBodyContent.map(({ sectionProps, components, id }) => (
            <Section {...sectionProps} key={id}>
              {components.map(({ component: Component, props }) => {
                return <Component key={props.entryId} {...props} />;
              })}
            </Section>
          ))}
        {customFinalCta ||
          <Section
            background={finalCtaBackgroundColor || colorSandBase}
            beforeContent={<CoverImage
              image={finalCtaImage}
              verticalAlignment={finalCtaImageAlignment}
            />}
            {...toFieldPath(hasFinalCtaImage ? 'finalCtaImage' : 'finalCtaBackgroundColor')}
          >
            <ContentfulFinalCta
              finalCtaHeading={finalCtaHeading}
              finalCtaBody={withFramework({
                gatsby: () => finalCtaBody?.finalCtaBody,
                next: () => finalCtaBody
              })}
              primaryCta={primaryCta}
              hasBackgroundImage={hasFinalCtaImage}
            />
          </Section>}
        {hasLearnMoreSection && (
          <Section forceFullWidth verticalSpacingCollapse={hasFaqSection ? 'bottom' : 'none'}>
            <HorizontalScrollingCards
              entryId={contentful_id}
              heading={getStringWithGlobals(learnMoreHeading)}
              subheading={getStringWithGlobals(withFramework({
                gatsby: () => learnMoreBody?.learnMoreBody,
                next: () => learnMoreBody,
              }))}
              cards={learnMoreCards.map(({ heading, linkUrl, footerText, contentfulId }) => ({
                heading: getStringWithGlobals(heading),
                link: linkUrl,
                bottomCopy: footerText,
                ...toObjectId(contentfulId),
                ...toFieldPath(
                  { fieldPath: 'heading', xpath: generateXPathSelectorString('Card_heading') },
                  { fieldPath: 'footerText', xpath: generateXPathSelectorString('HorizontalScrollingCards_footerText') }
                )
              }))}
            />
          </Section>
        )}
        {hasFaqSection && (
          <Section>
            <ContentfulFaq faqHeading={faqHeading} faqQuestions={faqQuestions} entryId={contentful_id} />
          </Section>
        )}
        {children}
      </PageContent>
    </PageLayout>
  );
};

ContentfulPageLayout.displayName = 'ContentfulPageLayout';

ContentfulPageLayout.propTypes = {
  pageContext: PropTypes.shape.isRequired,
  data: PropTypes.shape.isRequired,
  customPrimaryCta: PropTypes.node,
  customNotificationBanner: PropTypes.node,
  children: PropTypes.node,
};

export { ContentfulPageLayout };
