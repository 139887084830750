'use client';

import React from 'react';
import PropTypes from 'prop-types';
import { toFieldPath } from '@stackbit/annotations';
import { withFramework } from 'lib/get-framework';
import { FeatureCard } from 'src/components/common/FeatureCard';
import { classNames } from 'src/utils/css';
import { Media } from 'src/tapestry/core/media';
import { getMediaPropsFromContentfulMedia, getMediaPropsFromGatsbyContentfulMedia } from 'src/utils/contentful';
import { ContentfulCTA } from 'src/components/common/ContentfulCTA';
import { FluidText } from 'src/components/common/FluidText';
import { Background } from 'src/components/common/Background';
import { asSubsection } from 'src/components/layout/Subsection';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { withLocaleCheckboxes } from 'src/components/contentful/withLocaleCheckboxes';
import { getColorInfo } from 'src/utils/colors/color-mapping';
import { ctaContainer_, eyebrowBg_, disclaimer_ } from './ContentfulPromoBlock.module.scss';

const ContentfulPromoEyebrow = ({
  eyebrow,
  eyebrowColor,
  className,
}) => {
  const { getStringWithGlobals } = useTranslationContext();

  const eyebrowContent = typeof eyebrow === 'string' ? (
    <FluidText
      type="p"
      min="ws-eyebrow-md"
      max="ws-eyebrow-lg"
      {...toFieldPath('sectionEyebrow')}
    >
      {getStringWithGlobals(eyebrow)}
    </FluidText>
  ) : eyebrow;

  return (
    <Background background={eyebrowColor} className={classNames(className)}>
      {eyebrowContent}
    </Background>
  );
};

const ContentfulPromoBlockContent = asSubsection('ContentfulPromoBlock')(
  ({
    sectionHeading,
    sectionDisclaimer,
    primaryCta,
    secondaryCta,
    background,
    asset,
    style: {
      background: { alignSelf } = {},
    } = {},
    ...props
  }) => {
    const { getStringWithGlobals } = useTranslationContext();
    const { eyebrow, heading, subheading } = sectionHeading || {};

    const { secondaryColor: eyebrowColor } = getColorInfo(background);

    const hasPrimaryCta = primaryCta?.link;

    return (
      <FeatureCard
        variant="promo"
        preContent={eyebrow && (
          <ContentfulPromoEyebrow
            eyebrow={eyebrow}
            eyebrowColor={eyebrowColor}
            className={classNames(eyebrowColor && eyebrowBg_)}
          />
        )}
        heading={
          <FluidText
            type="h2"
            min="ws-display-md-sans"
            max="ws-display-xl-sans"
            {...toFieldPath('sectionHeading')}
          >
            {getStringWithGlobals(heading)}
          </FluidText>
        }
        details={
          <FluidText type="markdown" {...toFieldPath('sectionBody')}>
            {getStringWithGlobals(subheading)}
          </FluidText>
        }
        customControls={false}
        media={
          asset && (
            <Media
              {...withFramework({
                gatsby: () => getMediaPropsFromGatsbyContentfulMedia(asset),
                next: () => getMediaPropsFromContentfulMedia(asset),
              })}
              {...toFieldPath('background')}
            />
          )
        }
        mediaAlign={alignSelf}
        background={background}
        {...props}
      >
        {hasPrimaryCta && (
          <div className={ctaContainer_}>
            <ContentfulCTA
              size="xl"
              type="link"
              variant={secondaryCta?.link ? 'primary' : 'secondary'}
              {...primaryCta}
            />
            {secondaryCta?.link && (
              <ContentfulCTA type="link" variant="secondary" size="xl" {...secondaryCta} />
            )}
          </div>
        )}
        {sectionDisclaimer && (
          <FluidText
            className={disclaimer_}
            type="markdown"
            all="ws-text-xs"
            {...toFieldPath('sectionDisclaimer')}
          >
            {getStringWithGlobals(sectionDisclaimer)}
          </FluidText>
        )}
      </FeatureCard>
    );
  }
);

ContentfulPromoBlockContent.propTypes = {
  sectionHeading: PropTypes.shape({
    eyebrow: PropTypes.node,
    heading: PropTypes.string,
    subheading: PropTypes.string,
  }),
  sectionDisclaimer: PropTypes.string,
  primaryCta: PropTypes.shape({
    label: PropTypes.string,
    labelFieldPath: PropTypes.string,
    link: PropTypes.string,
  }),
  secondaryCta: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
    labelFieldPath: PropTypes.string,
  }),
  asset: PropTypes.node,
  background: PropTypes.string,
};

export const ContentfulPromoBlock = withLocaleCheckboxes(ContentfulPromoBlockContent);
