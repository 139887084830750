import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { classNames } from 'src/utils/css';
import { MARKDOWN_MODES } from 'src/tapestry/core/markdown';
import { Media } from 'src/tapestry/core/media';
import { SmartLink } from 'src/tapestry/core/links/smart-link';
import { FluidText } from 'src/components/common/FluidText';
import { Background } from 'src/components/common/Background';
import { useUserAttributesContext } from 'src/components/contexts/UserAttributesContext';
import { VideoControls } from 'src/components/common/VideoControls';
import { toXPathData } from 'src/utils/netlify-create/xpath';
import { ArrowLinkButton } from '../ArrowLinkButton';
import {
  featureCard_,
  featureCardLimitTextWidth_,
  featureCardWideSplit_,
  featureCardPromo_,
  featureCardPremium_,
  withBorder_,
  withOverflow_,
  alignCenter_,
  textContent_,
  media_ as mediaClass,
  mediaAlignFlexStart_,
  mediaAlignCenter_,
  mediaAlignFlexEnd_,
  videoControls_,
  featureCardNoMedia_,
  horizontalSpacingCollapseLeft_,
  horizontalSpacingCollapseRight_,
  horizontalSpacingCollapseAll_,
  verticalSpacingCollapseTop_,
  verticalSpacingCollapseBottom_,
  verticalSpacingCollapseAll_,
  childContent_,
  ctaButton_,
  smartLink_,
  active_,
} from './FeatureCard.module.scss';

const fluidConfigHeading = { min: 'ws-display-md-sans', max: 'ws-display-xl-sans' };
const fluidConfigDetails = { min: 'ws-text-lg', max: 'ws-text-2xl' };
const VARIANT_CLASSES = {
  default: '',
  wideHorizontalSplit: featureCardWideSplit_,
  promo: featureCardPromo_,
  premium: featureCardPremium_,
};

const HORIZONTAL_SPACING_CLASSES = {
  left: horizontalSpacingCollapseLeft_,
  right: horizontalSpacingCollapseRight_,
  all: horizontalSpacingCollapseAll_,
};

const VERTICAL_SPACING_CLASSES = {
  top: verticalSpacingCollapseTop_,
  bottom: verticalSpacingCollapseBottom_,
  all: verticalSpacingCollapseAll_,
};

const MEDIA_ALIGN_TO_CLASS = {
  'flex-start': mediaAlignFlexStart_,
  'center': mediaAlignCenter_,
  'flex-end': mediaAlignFlexEnd_,
};

const FeatureCard = ({
  className,
  containerElement,
  heading,
  headingClassName,
  details,
  preContent,
  media,
  mediaClassName,
  allowOverflow = false,
  addBorder = false,
  mediaAlign = 'flex-end',
  detailsClassName,
  children,
  textAlign = 'left',
  customControls = true,
  background,
  shouldLimitTextWidth = false,
  theme,
  variant = 'default',
  verticalSpacingCollapse,
  horizontalSpacingCollapse,
  href,
  ...restProps
}) => {
  const mediaRef = useRef();
  const { isPowerSaveMode } = useUserAttributesContext();
  const [videoEl, setVideoEl] = useState();
  const isCenterAligned = textAlign === 'center';
  const [isActive, setIsActive] = useState(false);
  const handleMouseEnter = () => setIsActive(true);
  const handleMouseLeave = () => setIsActive(false);

  const headingContent = isString(heading) ? (
    <FluidText
      type="h2"
      {...toXPathData('FeatureCard_heading')}
      {...fluidConfigHeading}
      className={classNames('FeatureCard-Heading', headingClassName)}
    >
      {heading}
    </FluidText>
  ) : (
    heading &&
    React.cloneElement(heading, {
      className: classNames('FeatureCard-Heading', headingClassName, heading?.props?.className),
    })
  );

  const subheadingContent = isString(details) ? (
    <FluidText
      type="markdown"
      {...toXPathData('FeatureCard_subheading')}
      {...fluidConfigDetails}
      markdownProps={{ mode: MARKDOWN_MODES.inlineText }}
      className={classNames('FeatureCard-Details', detailsClassName)}
    >
      {details}
    </FluidText>
  ) : (
    details
  );

  // If media coming in is our "Media" component from tapestry
  // and if customControls is true, turn off controls.
  const mediaIsInstanceOfMedia = media?.type === Media;
  const useCustomControls = videoEl && customControls && mediaIsInstanceOfMedia;
  const mediaComponent = useCustomControls
    ? React.cloneElement(media, {
      controls: false,
    })
    : media;

  useEffect(() => {
    const childVideoElement = mediaRef.current?.getElementsByTagName('video')[0];
    if (childVideoElement) setVideoEl(childVideoElement);
  }, []);

  const containerProps = {
    ...restProps,
    className: classNames(
      featureCard_,
      VARIANT_CLASSES[variant],
      addBorder && withBorder_,
      allowOverflow && withOverflow_,
      shouldLimitTextWidth && featureCardLimitTextWidth_,
      isCenterAligned && alignCenter_,
      VERTICAL_SPACING_CLASSES[verticalSpacingCollapse],
      className
    ),
  };

  const cardContents = (
    <>
      <div
        className={classNames(
          textContent_,
          !mediaComponent && featureCardNoMedia_,
          HORIZONTAL_SPACING_CLASSES[horizontalSpacingCollapse],
          VERTICAL_SPACING_CLASSES[verticalSpacingCollapse]
        )}
      >
        {preContent && isString(preContent) && (
          <p className="FeatureCard-PreContent">{preContent}</p>
        )}
        {preContent && !isString(preContent) && (
          <div className="FeatureCard-PreContent">{preContent}</div>
        )}
        {headingContent}
        {subheadingContent}
        {href && (
          <ArrowLinkButton
            isActive={isActive}
            className={ctaButton_}
          />
        )}
        {children && <div className={childContent_}>{children}</div>}
      </div>
      {mediaComponent && (
        <div ref={mediaRef} className={classNames(mediaClass, mediaClassName, MEDIA_ALIGN_TO_CLASS?.[mediaAlign])}>
          {mediaComponent}
        </div>
      )}
      {useCustomControls && videoEl && !isPowerSaveMode && (
        <VideoControls
          className={classNames(
            'FeatureCard-VideoControls',
            videoControls_,
          )}
          videoElement={videoEl}
        />
      )}
    </>
  );

  if (containerElement) {
    return React.cloneElement(containerElement, containerProps, cardContents);
  }

  return (
    <Background
      {...toXPathData('FeatureCard_background')}
      isDarkTheme={theme === 'dark'}
      as={SmartLink}
      background={background}
      href={href}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...containerProps}
      className={classNames(smartLink_, isActive && active_, containerProps.className)}
    >
      {cardContents}
    </Background>
  );
};

FeatureCard.propTypes = {
  className: PropTypes.string,
  background: PropTypes.string,
  containerElement: PropTypes.node,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  details: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  media: PropTypes.node,
  mediaAlign: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'none']), // 'none' for custom alignment
  mediaClassName: PropTypes.string,
  headingClassName: PropTypes.string,
  children: PropTypes.node,
  textAlign: PropTypes.oneOf(['left', 'center']),
  preContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  customControls: PropTypes.bool,
  shouldLimitTextWidth: PropTypes.bool,
  theme: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'wideHorizontalSplit', 'promo', 'premium']),
  horizontalSpacingCollapse: PropTypes.oneOf(['left', 'right', 'all']),
  verticalSpacingCollapse: PropTypes.oneOf(['top', 'bottom', 'all']),
  href: PropTypes.string,
};

export { FeatureCard };
