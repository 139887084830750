import React from 'react';
import { classNames } from 'src/utils/css';
import PropTypes from 'prop-types';
import { Background } from 'src/components/common/Background';
import { variantDefault_, variantXsmall_, variantXl_ } from './TagPill.module.scss';

const VARIANTS = {
  default: {
    class: variantDefault_,
    textClass: 'ws-text-md-medium',
  },
  xs: {
    class: variantXsmall_,
    textClass: 'ws-text-xs-medium',
  },
  xl: {
    class: variantXl_,
    textClass: 'ws-text-md',
  },
};

const TagPill = ({ className, color, background, variant = 'default', children, style }) => {
  const variantProps = VARIANTS[variant];
  return (
    <Background
      as="span"
      background={background}
      className={classNames(variantProps.textClass, variantProps.class, className)}
      style={{
        color,
        ...style,
      }}
    >
      {children}
    </Background>
  );
};

TagPill.propTypes = {
  /**
   * The text color of the tag as a hex code
   */
  color: PropTypes.string,

  /**
   * The background color of the tag
   */
  background: PropTypes.string,

  /**
   * Preset styles
   *
   * @default default
   */
  variant: PropTypes.oneOf(['default', 'xs', 'xl']),

  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export { TagPill };
