import React from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { asSubsection } from 'src/components/layout/Subsection';
import { FluidText, DEFAULT_CONFIG_BY_ELEMENT_TYPE } from 'src/components/common/FluidText';
import { classNames } from 'src/utils/css';
import {
  closingCtaBlock_,
  hasBackgroundImage_,
  headingDisplay_,
  subheadingDisplay_,
  ctaDisplay_,
  fullscreen_,
} from './CallToActionBlock.module.scss';

const CallToActionBlock = asSubsection('CallToActionBlock')(
  ({ heading, subheading, cta, fullscreen = true, hasBackgroundImage = false, className, ...rest }) => {
    const headingClasses = classNames(headingDisplay_);
    const subHeadingClasses = classNames(subheadingDisplay_);

    const headingComponent = isString(heading) ? (
      <FluidText type="h2" className={headingClasses} {...DEFAULT_CONFIG_BY_ELEMENT_TYPE.h1}>
        {heading}
      </FluidText>
    ) : (
      heading &&
      React.cloneElement(heading, {
        className: classNames(headingClasses, heading?.props?.className),
      })
    );

    const subheadingComponent = isString(subheading) ? (
      <FluidText type="p" className={subHeadingClasses} isBalanced>
        {subheading}
      </FluidText>
    ) : (
      subheading &&
      React.cloneElement(subheading, {
        className: classNames(subHeadingClasses, subheading?.props?.className),
      })
    );

    return (
      <div className={classNames(closingCtaBlock_, fullscreen && fullscreen_, hasBackgroundImage && hasBackgroundImage_, className)} {...rest}>
        {headingComponent}
        {subheadingComponent}
        {cta && <div className={ctaDisplay_}>{cta}</div>}
      </div>
    );
  }
);

CallToActionBlock.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  subheading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  cta: PropTypes.node,
  fullscreen: PropTypes.bool,
};

CallToActionBlock.displayName = 'CallToActionBlock';

export { CallToActionBlock };
