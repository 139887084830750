import React from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { classNames } from 'src/utils/css';
import { useTranslationContext, useTranslation } from 'src/components/contexts/TranslationContext';
import { useComponentContext } from 'src/components/layout/ComponentContext';
import { CaretDown } from '../Icons';
import { FormFieldLabel } from '../FormFieldLabel';
import {
  wrapper_,
  select_,
  selectWrapper_,
  caret_,
  darkTheme_,
  variantSecondary_,
  sizeSmall_,
  sizeLarge_,
} from './FormSelectField.module.scss';
import { translations } from './FormSelectField.translations';

const VARIANTS_DEFAULT = 'default';
const VARIANTS_SECONDARY = 'secondary';
const SIZES = {
  sm: classNames(sizeSmall_, 'ws-text-lg'),
  md: 'ws-text-xl',
  lg: classNames(sizeLarge_, 'ws-text-2xl'),
};

const FormSelectOption = ({ option, ...rest }) => {
  if (isString(option)) {
    return <option {...rest}>{option}</option>;
  }

  const { label, ...restOption } = option;

  return (
    <option {...restOption} {...rest}>
      {label}
    </option>
  );
};

export const FormSelectField = ({
  id,
  label,
  value,
  defaultValue,
  options,
  isRequired = false,
  showRequiredLabel = false,
  hiddenLabel = true,
  name,
  onBlur,
  onChange,
  onFocus,
  className,
  variant,
  size = 'md',
  tooltipDescription,
  ...rest
}) => {
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);
  const { shouldUseDarkTheme } = useComponentContext();

  const requiredText = useTranslation('form-select-field::required');

  const inputId = id;
  const labelId = `${inputId}-form-select-label`;

  // Support for existing <option> instances that use the selected attribute - React prefers us to use value prop
  const val = value ?? defaultValue ?? options.find(opt => opt.selected)?.value ?? options[0]?.value ?? options[0];
  // defaultValue, in most cases, avoids the need to manage value-state from parent - <select> manages state internally
  const valueConfig = { [value ? 'value' : 'defaultValue']: val };

  const resolvedInput = {
    name,
    onBlur,
    onChange,
    onFocus,
    ...valueConfig,
    ...rest,
  };

  return (
    <div
      className={classNames(
        wrapper_,
        variant === VARIANTS_SECONDARY && variantSecondary_,
        shouldUseDarkTheme && darkTheme_,
        SIZES[size],
        className)}
    >
      <div className={classNames(selectWrapper_)}>
        <select
          {...resolvedInput}
          id={inputId}
          required={isRequired}
          className={select_}
          aria-labelledby={labelId}
          aria-required={isRequired}
        >
          {options.map(opt => {
            const optionLabel = opt.label ?? opt;
            // "selected" is deprecated attribute for option elements, though we still use it to identify the
            // defaultValue which gets assigned to the select element. This prevents the browser from throwing an error.
            if (opt.selected !== undefined) delete opt.selected;
            return (
              <FormSelectOption key={`form-select-option-${id}-${optionLabel}`} option={opt} />
            );
          })}
        </select>
        <CaretDown className={caret_} />
      </div>
      <FormFieldLabel
        labelId={labelId}
        htmlFor={inputId}
        label={label}
        requiredLabel={requiredText}
        showLabel={!hiddenLabel}
        showRequiredLabel={showRequiredLabel}
        tooltipDescription={tooltipDescription}
      />
    </div>
  );
};

FormSelectField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  showRequiredLabel: PropTypes.bool,
  // Hide visually, but not from screen readers
  hiddenLabel: PropTypes.bool,
  // The following should only be provided if you are _not_ using FinalForm
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        disabled: PropTypes.bool,
        selected: PropTypes.bool,
      })
    ),
  ]),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf([VARIANTS_DEFAULT, VARIANTS_SECONDARY]),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};
