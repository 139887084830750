import { pmt } from 'financial';
import { formatDecimalToPercentage } from 'src/utils/formatters';

export const CLIENT_STATUS_TAB_LABELS = ['Non-client', 'Core', 'Premium', 'Generation'];
export const CLIENT_STATUS_RATES = { 'non-client': 0, 'core': 0.0005, 'premium': 0.001, 'generation': 0.0015 };
export const PROPERTY_PRICE_DEFAULT = 500000;
export const DEFAULT_DOWN_PAYMENT_PERCENT = 20;
export const DEFAULT_DOWN_PAYMENT_AMOUNT = (DEFAULT_DOWN_PAYMENT_PERCENT / 100) * PROPERTY_PRICE_DEFAULT;
export const DEFAULT_MORTGAGE_AMOUNT = 500000;
export const DEFAULT_MORTGAGE_AMOUNT_BUYING = DEFAULT_DOWN_PAYMENT_AMOUNT;
export const DEFAULT_CLIENT_STATUS_REBATE = 0;
export const DEFAULT_CLIENT_DEPOSIT_REBATE = 0;
export const MORTGAGE_AMOUNT_REBATE_STEP = 0.0005;
export const MORTGAGE_AMOUNT_REBATE_THRESHOLD_STEP = 50000;
export const TERM_MINIMUM = 24; // months
export const TERM_TYPE_FIXED = 'fixed';
export const TERM_TYPE_ADJUSTABLE = 'adjustable';
export const TERM_DURATION_DEFAULT = 60;
export const MONTHS_IN_YEAR = 12;
export const AMORTIZATION_DEFAULT_VALUE = 25 * MONTHS_IN_YEAR;
// 10 years, 11 years, 12 years, 13 years... 30 years

export const AMORTIZATION_OPTIONS_INSURED_MAX = 25;
const AMORTIZATION_OPTIONS_UNINSURED_MAX = 30;
const AMORTIZATION_OPTIONS_ALL_MIN = 10;
const AMORTIZATION_OPTIONS_INSURED_LENGTH = AMORTIZATION_OPTIONS_INSURED_MAX - AMORTIZATION_OPTIONS_ALL_MIN + 1;
const AMORTIZATION_OPTIONS_UNINSURED_LENGTH = AMORTIZATION_OPTIONS_UNINSURED_MAX - AMORTIZATION_OPTIONS_ALL_MIN + 1;
export const AMORITIZATION_OPTIONS_INSURED =
  [...Array(AMORTIZATION_OPTIONS_INSURED_LENGTH).keys()].map(i => i + AMORTIZATION_OPTIONS_ALL_MIN).map(i => i * MONTHS_IN_YEAR);
export const AMORITIZATION_OPTIONS_UNINSURED =
  [...Array(AMORTIZATION_OPTIONS_UNINSURED_LENGTH).keys()].map(i => i + AMORTIZATION_OPTIONS_ALL_MIN).map(i => i * MONTHS_IN_YEAR);

export const MORTGAGE_TYPE_TAB_LABELS = ['Buying', 'Renewing'];
export const PAYMENT_FREQUENCY_OPTIONS = [{ label: 'Monthly', value: 12 }, { label: 'Semi-monthly', value: 24 }, { label: 'Bi-weekly', value: 26 }, { label: 'Weekly', value: 52 }];

const BG_CORE_PREMUIM = `linear-gradient(220deg, #e4e9d3 0%, #e4e9d3 40%, #e4e9d3 60%, #e4e9d3 100%)`;
const BG_GRADIENT_PREMUIM = `linear-gradient(220deg, #e5e1d9 0%, #f9f7f4 40%, #f9f7f4 60%, #e5e1d9 100%)`;
const BG_GRADIENT_GENERATION = `linear-gradient(220deg, #21252c 0%, #373941 40%, #373941 60%, #21252c 100%)`;
const TEXT_COLOR_DEFAULT = '#32302f';
const TEXT_COLOR_LIGHT = '#fff';
export const THEMES = {
  core: {
    root: {
      '--calculator-tier-color': BG_CORE_PREMUIM,
    },
    wrapper: {
      '--mortgage-rebate-text-color': TEXT_COLOR_DEFAULT,
    },
    tabSelectors: {
      '--tab-selector-input-text-color': TEXT_COLOR_DEFAULT,
      '--tab-selector-input-active-text-color': TEXT_COLOR_DEFAULT,
      '--tab-selector-input-button-color': BG_CORE_PREMUIM,
    },
    slider: {
      '--calculator-slider-button-text-color': TEXT_COLOR_DEFAULT,
      '--calculator-slider-button-bg-color': BG_CORE_PREMUIM,
    },
    breakdownTable: {
      '--breakdown-table-button-text-color': TEXT_COLOR_DEFAULT,
      '--breakdown-table-button-bg-color': BG_CORE_PREMUIM,
    },
  },
  premium: {
    root: {
      '--calculator-tier-color': BG_GRADIENT_PREMUIM,
    },
    wrapper: {
      '--mortgage-rebate-text-color': TEXT_COLOR_DEFAULT,
    },
    tabSelectors: {
      '--tab-selector-input-text-color': TEXT_COLOR_DEFAULT,
      '--tab-selector-input-active-text-color': TEXT_COLOR_DEFAULT,
      '--tab-selector-input-button-color': BG_GRADIENT_PREMUIM,
    },
    slider: {
      '--calculator-slider-button-text-color': TEXT_COLOR_DEFAULT,
      '--calculator-slider-button-bg-color': BG_GRADIENT_PREMUIM,
    },
    breakdownTable: {
      '--breakdown-table-button-text-color': TEXT_COLOR_DEFAULT,
      '--breakdown-table-button-bg-color': BG_GRADIENT_PREMUIM,
    },
  },
  generation: {
    root: {
      '--calculator-tier-color': BG_GRADIENT_GENERATION,
    },
    wrapper: {
      '--mortgage-rebate-text-color': TEXT_COLOR_LIGHT,
    },
    tabSelectors: {
      '--tab-selector-input-active-text-color': TEXT_COLOR_LIGHT,
      '--tab-selector-input-text-color': BG_GRADIENT_GENERATION,
      '--tab-selector-input-button-color': BG_GRADIENT_GENERATION,
    },
    slider: {
      '--calculator-slider-button-text-color': TEXT_COLOR_LIGHT,
      '--calculator-slider-button-bg-color': BG_GRADIENT_GENERATION,
    },
    breakdownTable: {
      '--breakdown-table-button-text-color': TEXT_COLOR_LIGHT,
      '--breakdown-table-button-bg-color': BG_GRADIENT_GENERATION,
    },
  },
};

export function pmtRate(rate, annualPayments, ammortization, mortgageAmount) {
  return pmt((1 + rate / 2) ** (2 / 12) - 1, annualPayments * ammortization, -mortgageAmount);
}

export const formatTierRebate = (rebateDecimal, locale) => {
  return formatDecimalToPercentage(rebateDecimal, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    locale,
  });
};

export const monthsToTimePeriodLabel = months => {
  if (months < 12) return `${months} month`;
  return `${months / 12} year`;
};

export const getTermId = (term, type = TERM_TYPE_FIXED) => `${term}-${type}`;
export const getTermById = (data, id) => data.find(term => term.value === id);
const getTermLabel = (label, type) => `${label} ${type}`;

export const processTermData = data => {
  return data
    // remove terms that are less than 2 years
    .filter(term => term.term >= TERM_MINIMUM)
    // sort terms by duration
    .sort((a, b) => {
      const termDurationA = Number(a.term);
      const termDurationB = Number(b.term);
      if (termDurationA === termDurationB) return 0;
      return termDurationA < termDurationB ? -1 : 1;
    })
    // format data as <select> options
    .reduce((terms, term) => {
      const label = `${monthsToTimePeriodLabel(term.term)}`;
      if (term.rate && !term.primeRate) {
        terms.push({ label: `${getTermLabel(label, TERM_TYPE_FIXED)}`, value: `${getTermId(term.term, TERM_TYPE_FIXED)}`, 'data-duration': term.term, 'data-rate': term.rate });
      }
      if (term.primeRate) {
        terms.push({ label: `${getTermLabel(label, TERM_TYPE_ADJUSTABLE)}`, value: `${getTermId(term.term, TERM_TYPE_ADJUSTABLE)}`, 'data-duration': term.term, 'data-rate': term.rate });
      }
      return terms;
    }, []);
};
