import React from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { RefreshCell } from 'src/components/layout/Grid';
import { useComponentContext } from 'src/components/layout/ComponentContext';
import { formatCurrency, formatDecimalToPercentage, formatDollarAmountAsYouType, setCursorForDollarAmount } from 'src/utils/formatters';
import { FluidText } from 'src/components/common/FluidText';
import { classNames } from 'src/utils/css';
import { clamp } from 'src/utils/math';
import { capitalizeFirstLetter } from 'src/utils/strings';
import { EVENT_NAMES, trackAnalyticsEvent } from 'src/services/analytics-service';
import { FormSelectField } from '../../../FormSelectField';
import { TabSelector } from '../../../TabSelector';
import { FormInputField } from '../../../FormInputField';
import CalculatorSlider from '../CalculatorSlider/CalculatorSlider.component';
import { FormFieldLabel } from '../../../FormFieldLabel';
import { useMortgageCalculatorData } from '../CalculatorProvider/CalculatorProvider.component';
import { CLIENT_STATUS_TAB_LABELS, CLIENT_STATUS_RATES, AMORITIZATION_OPTIONS_INSURED, AMORITIZATION_OPTIONS_UNINSURED, MORTGAGE_TYPE_TAB_LABELS, PAYMENT_FREQUENCY_OPTIONS, MORTGAGE_AMOUNT_REBATE_STEP, MORTGAGE_AMOUNT_REBATE_THRESHOLD_STEP, DEFAULT_DOWN_PAYMENT_AMOUNT, DEFAULT_DOWN_PAYMENT_PERCENT, formatTierRebate, monthsToTimePeriodLabel, PROPERTY_PRICE_DEFAULT, TERM_DURATION_DEFAULT, TERM_TYPE_FIXED, getTermById, getTermId, AMORTIZATION_OPTIONS_INSURED_MAX, MONTHS_IN_YEAR } from '../../MortgageRebateV2.helpers';
import { loanTypeTabSelector_, mobileSliderValuePill_, mortgageAmount_, downPaymentPercent_, lowerInputLabel_, inputMessage_ } from '../../MortgageRebateV2.module.scss';

const InputPercentCount = ({ label, percentValue }) => {
  return (
    <p>
      <FluidText type="span" min="ws-text-md" max="ws-text-lg">
        {`${label}: `}
      </FluidText>
      <FluidText type="span" min="ws-text-md-medium" max="ws-text-lg-medium">
        {percentValue}
      </FluidText>
    </p>
  );
};

const InsuredModeMessage = ({ message }) => {
  return (
    <div aria-live="polite" className={classNames(!message && 'sr-only')}>
      <FluidText type="p" all="ws-text-sm-medium" className={classNames(inputMessage_, !message && 'sr-only')}>{message}</FluidText>
    </div>);
};

const DEBOUNCE_TIMEOUT_MS = 1000;
const DEFAULT_MORTGAGE_TYPE_INDEX = 1;
const DEFAULT_MORTGAGE_TYPE = MORTGAGE_TYPE_TAB_LABELS[DEFAULT_MORTGAGE_TYPE_INDEX];

const trackPropertyPriceEvent = (val, content) => trackAnalyticsEvent(EVENT_NAMES.PROPERTY_PRICE_INPUT_UPDATE, { label: val }, content);
const trackPropertyPriceEventDebounced = debounce(trackPropertyPriceEvent, DEBOUNCE_TIMEOUT_MS);

const trackDownPaymentPercentEvent = (val, content) => trackAnalyticsEvent(EVENT_NAMES.DOWN_PAYMENT_PERCENT_INPUT_UPDATE, { label: val }, content);
const trackDownPaymentPercentEventDebounced = debounce(trackDownPaymentPercentEvent, DEBOUNCE_TIMEOUT_MS);

const trackDownPaymentDollarEvent = (val, content) => trackAnalyticsEvent(EVENT_NAMES.DOWN_PAYMENT_DOLLAR_INPUT_UPDATE, { label: val }, content);
const trackDownPaymentDollarEventDebounced = debounce(trackDownPaymentDollarEvent, DEBOUNCE_TIMEOUT_MS);

const trackMortgageAmountEvent = (val, content) => trackAnalyticsEvent(EVENT_NAMES.MORTGAGE_AMOUNT_INPUT_UPDATE, { label: val }, content);
const trackMortgageAmountEventDebounced = debounce(trackMortgageAmountEvent, DEBOUNCE_TIMEOUT_MS);

const trackSliderEvent = (val, content) => trackAnalyticsEvent(EVENT_NAMES.TRACK_SLIDER_INPUT, { label: val }, content);
const trackSliderEventDebounced = debounce(trackSliderEvent, DEBOUNCE_TIMEOUT_MS);

export const CalculatorInputs = ({
  locale = 'en-CA',
  inputSize = 'lg',
  isTabSelectorFullWidth = false,
  termAmoritizationPaymentFrequencyCellWidthDesktop = 3,
  tabSelectorMortgageTypeRef,
  tabSelectorClientStatusRef,
  depositSliderRef
}) => {
  const { state, dispatch } = useMortgageCalculatorData();
  const componentContext = useComponentContext();

  const updateTerms = (termData, targetTermDuration = TERM_DURATION_DEFAULT, targetMortgageType = TERM_TYPE_FIXED) => {
    const selectedTerm = getTermById(termData, getTermId(targetTermDuration, targetMortgageType));

    dispatch({ type: 'SET_TERM_DURATION', payload: selectedTerm['data-duration'] });
    dispatch({ type: 'SET_TERM_SELECTIONS', payload: termData });
    dispatch({ type: 'SET_TERM_VALUE', payload: selectedTerm.value });
    dispatch({ type: 'SET_INTEREST_RATE', payload: Number(selectedTerm['data-rate']) });
  };

  const handleMortgageTypeChange = val => {
    dispatch({ type: 'SET_MORTGAGE_TYPE', payload: val });

    switch (val.toLowerCase()) {
      case 'buying':
        dispatch({ type: 'SET_PROPERTY_PRICE', payload: PROPERTY_PRICE_DEFAULT });
        dispatch({ type: 'SET_DOWN_PAYMENT_PERCENT', payload: DEFAULT_DOWN_PAYMENT_PERCENT });
        dispatch({ type: 'SET_DOWN_PAYMENT_DOLLAR', payload: DEFAULT_DOWN_PAYMENT_AMOUNT });
        dispatch({ type: 'SET_MORTGAGE_AMOUNT', payload: PROPERTY_PRICE_DEFAULT - DEFAULT_DOWN_PAYMENT_AMOUNT });
        updateTerms(state.allTermData.insured);
        break;
      case 'renewing':
        updateTerms(state.allTermData.insured);
        break;
      case 'refinancing':
        updateTerms(state.allTermData.uninsured);
        break;
      default:
        break;
    }

    trackAnalyticsEvent(EVENT_NAMES.LOAN_TYPE_TAB_SELECT, { label: val?.toLowerCase() }, componentContext);
  };

  const handlePropertyPriceChange = (e, val) => {
    const normalizedPropertyPrice = Math.abs(clamp(0, Number(val.replace(/[^0-9.-]+/g, '')), Infinity));
    dispatch({ type: 'SET_PROPERTY_PRICE', payload: normalizedPropertyPrice });

    const downPaymentDollarsNormalized = normalizedPropertyPrice * (state.downPaymentPercent / 100);
    dispatch({ type: 'SET_DOWN_PAYMENT_DOLLAR', payload: downPaymentDollarsNormalized });
    dispatch({ type: 'SET_MORTGAGE_AMOUNT', payload: normalizedPropertyPrice - downPaymentDollarsNormalized });

    trackPropertyPriceEventDebounced(normalizedPropertyPrice, componentContext);
  };

  const handleDownPaymentPercentChange = e => {
    const inputEl = e.target;
    const int = Math.max(0, Math.min(100, parseInt(inputEl.value, 10)));
    const decimal = !Number.isNaN(int) ? int / 100 : 0;
    const percentString = formatDecimalToPercentage(decimal);
    const percentCharIndex = percentString.indexOf('%');

    dispatch({ type: 'SET_DOWN_PAYMENT_PERCENT', payload: !Number.isNaN(int) ? int : 0 });

    const downPaymentDollars = state.propertyPrice * decimal;
    dispatch({ type: 'SET_DOWN_PAYMENT_DOLLAR', payload: downPaymentDollars });
    dispatch({ type: 'SET_MORTGAGE_AMOUNT', payload: state.propertyPrice - downPaymentDollars });

    trackDownPaymentPercentEventDebounced(percentString, componentContext);

    // forces an event cycle before setting the cursor
    setTimeout(() => {
      const position = Math.min(inputEl.selectionStart, percentCharIndex);
      inputEl.setSelectionRange(position, position);
    }, 0);
  };

  const handleDownPaymentDollarChange = (e, val) => {
    const dollars = Math.abs(Number(val.replace(/[^0-9.-]+/g, '')));
    if (dollars > state.propertyPrice) {
      // eslint-disable-next-line no-console
      console.error('Down payment cannot exceed property price');
      return;
    }

    dispatch({ type: 'SET_DOWN_PAYMENT_DOLLAR', payload: dollars });

    const percent = (dollars * 100) / state.propertyPrice;
    dispatch({ type: 'SET_DOWN_PAYMENT_PERCENT', payload: percent });

    const mortgageDollars = state.propertyPrice - dollars;
    dispatch({ type: 'SET_MORTGAGE_AMOUNT', payload: mortgageDollars });

    trackDownPaymentDollarEventDebounced(dollars, componentContext);
  };

  const handleMortgageAmountChange = (e, val) => {
    // need to convert back to number for calcs
    const mortgageUnformatted = Math.abs(Number(val.replace(/[^0-9.-]+/g, '')));

    if (state.mortgageType === 'Buying') {
      if (mortgageUnformatted > state.propertyPrice) {
        // eslint-disable-next-line no-console
        console.error('Mortgage amount cannot exceed property price');
        return;
      }

      const downPaymentDollars = state.propertyPrice - mortgageUnformatted;
      dispatch({ type: 'SET_DOWN_PAYMENT_DOLLAR', payload: downPaymentDollars });

      const percent = downPaymentDollars / state.propertyPrice;
      dispatch({ type: 'SET_DOWN_PAYMENT_PERCENT', payload: percent });
    }

    dispatch({ type: 'SET_MORTGAGE_AMOUNT', payload: mortgageUnformatted });

    trackMortgageAmountEventDebounced(mortgageUnformatted, componentContext);
  };

  const setInsuredMode = (isUninsuredRate, hasUninsuredOption) => {
    // display 30 year amortization if a loan is eligible for uninsured rates, otherwise 25
    const amortizationSelections = (isUninsuredRate || hasUninsuredOption) ? AMORITIZATION_OPTIONS_UNINSURED : AMORITIZATION_OPTIONS_INSURED;
    dispatch({ type: 'SET_AMORTIZATION_SELECTIONS', payload: amortizationSelections });
    // display a message if the user is on an uninsured rate
    dispatch({ type: 'SET_TERM_MESSAGE', payload: isUninsuredRate && 'Uninsured rates are only available for 3 or 5 year fixed term lengths' });
    dispatch({ type: 'SET_AMORTIZATION_MESSAGE', payload: isUninsuredRate && 'Any amortization period over 25 years results in an uninsured rate' });
  };

  const handleTermLengthChange = e => {
    const term = e.target.options[e.target.selectedIndex];
    const rate = term.getAttribute('data-rate');
    const duration = term.getAttribute('data-duration');
    const hasUninsuredOption = term.getAttribute('data-uninsured-rate-available') === 'true';
    const isUninsuredRate = state.amortizationValue > (AMORTIZATION_OPTIONS_INSURED_MAX * MONTHS_IN_YEAR);

    dispatch({ type: 'SET_TERM_VALUE', payload: e.target.value });
    dispatch({ type: 'SET_INTEREST_RATE', payload: rate });
    dispatch({ type: 'SET_TERM_DURATION', payload: duration });

    setInsuredMode(isUninsuredRate, hasUninsuredOption);

    trackAnalyticsEvent(EVENT_NAMES.TERM_INPUT_UPDATE, { label: duration }, componentContext);
  };

  const handleAmortizationPeriodChange = e => {
    const amortization = e.target.value;
    const activeTerm = state.termSelections.find(term => term.value === state.termValue);
    const hasUninsuredOption = activeTerm?.['data-uninsured-rate-available'] === 'true';
    const isUninsuredRate = amortization > (AMORTIZATION_OPTIONS_INSURED_MAX * MONTHS_IN_YEAR);

    const termData = isUninsuredRate ? state.allTermData.uninsured : state.allTermData.insured;
    const rate = termData.find(term => term.value === state.termValue)?.['data-rate'];

    dispatch({ type: 'SET_INTEREST_RATE', payload: rate });
    dispatch({ type: 'SET_TERM_SELECTIONS', payload: termData });

    setInsuredMode(isUninsuredRate, hasUninsuredOption);

    dispatch({ type: 'SET_AMORTIZATION_VALUE', payload: amortization });

    trackAnalyticsEvent(EVENT_NAMES.AMORTIZATION_INPUT_UPDATE, { label: amortization }, componentContext);
  };

  const handlePaymentFrequencyChange = e => {
    const frequency = parseInt(e.target.value, 10);
    dispatch({ type: 'SET_PAYMENT_FREQUENCY', payload: frequency });

    trackAnalyticsEvent(EVENT_NAMES.PAYMENT_FREQUENCY_INPUT_UPDATE, { label: frequency }, componentContext);
  };

  const handleTierSelectorChange = val => {
    const tierKey = val.toLowerCase();
    dispatch({ type: 'SET_TIER_LEVEL', payload: tierKey });
    dispatch({ type: 'SET_CLIENT_STATUS_REBATE', payload: CLIENT_STATUS_RATES[tierKey] });

    trackAnalyticsEvent(EVENT_NAMES.CLIENT_STATUS_TAB_UPDATE, { label: tierKey }, componentContext);
  };

  const handleSliderChange = e => {
    const newDepositValue = e.target.value;
    const increments = Math.floor(newDepositValue / MORTGAGE_AMOUNT_REBATE_THRESHOLD_STEP);
    const mortgageDepositRebate = increments * MORTGAGE_AMOUNT_REBATE_STEP;

    dispatch({ type: 'SET_DEPOSIT_REBATE', payload: mortgageDepositRebate });
    dispatch({ type: 'SET_DEPOSIT_VALUE', payload: newDepositValue });

    trackSliderEventDebounced(newDepositValue, componentContext);
  };

  return (
    <>
      <RefreshCell
        all={2}
        lg={{ width: 6, left: 7 }}
        className={loanTypeTabSelector_}
      >
        <TabSelector ref={tabSelectorMortgageTypeRef} id="mortgageType" tabs={MORTGAGE_TYPE_TAB_LABELS} initialTab={DEFAULT_MORTGAGE_TYPE} value={state.mortgageType} onChange={handleMortgageTypeChange} size="sm" />
      </RefreshCell>
      {state.mortgageType === 'Buying' && (
      <>
        <RefreshCell all={2} lg={{ width: 3 }} className={mortgageAmount_}>
          <FormInputField
            id="propertyPrice"
            label="Property price"
            customFormatter={formatDollarAmountAsYouType}
            onValueUpdate={setCursorForDollarAmount}
            onChange={handlePropertyPriceChange}
            autoComplete="off"
            value={formatCurrency(state.propertyPrice, { locale })}
            hasExternalLabel
            size={inputSize}
            tooltipDescription="How much is the property you’re looking to purchase? If you don’t have a specific spot in mind, tell us what you’re budgeting for."
          />
        </RefreshCell>
        <RefreshCell all={1} lg={{ width: 3 }} className={classNames(mortgageAmount_, downPaymentPercent_)}>
          <FormInputField
            id="downPaymentPercent"
            label="Down payment (%)"
            type="text"
            customFormatter={formatDecimalToPercentage}
            onChange={handleDownPaymentPercentChange}
            autoComplete="off"
            value={formatDecimalToPercentage(state.downPaymentPercent / 100, { locale })}
            hasExternalLabel
            size={inputSize}
            tooltipDescription={`A down payment is the amount of money you put towards the price of a home. What percentage of the property price are you planning on paying upfront?\n\nThe minimum down payment for properties under $1 million is 5% on the first $500,000 and 10% on any portion after that. For properties over $1 million, it's minimally 20% or more.`}
          />
        </RefreshCell>
        <RefreshCell all={1} lg={{ width: 3 }} className={mortgageAmount_}>
          <FormInputField
            id="downPaymentDollar"
            label="Down payment ($)"
            customFormatter={formatDollarAmountAsYouType}
            onValueUpdate={setCursorForDollarAmount}
            onChange={handleDownPaymentDollarChange}
            autoComplete="off"
            value={formatCurrency(state.downPaymentDollar, { locale })}
            hasExternalLabel
            size={inputSize}
            tooltipDescription={`A down payment is the amount of money you put towards the price of a home. What percentage of the property price are you planning on paying upfront?\n\nThe minimum down payment for properties under $1 million is 5% on the first $500,000 and 10% on any portion after that. For properties over $1 million, it's minimally 20% or more.`}
          />
        </RefreshCell>
      </>
      )}
      <RefreshCell all={1} lg={{ width: 3 }}>
        <FormInputField
          id="mortgageAmount"
          label="Mortgage amount"
          customFormatter={formatDollarAmountAsYouType}
          onValueUpdate={setCursorForDollarAmount}
          onChange={handleMortgageAmountChange}
          autoComplete="off"
          value={formatCurrency(state.mortgageAmount, { locale })}
          hasExternalLabel
          size={inputSize}
          tooltipDescription={`The mortgage amount is the sum of money you’ll need to borrow from your lender. It’s the property price minus your down payment.\n\nThese rates are subject to qualification and based on review of personal information, property valuation, and any other factors Pine determines relevant to your mortgage application.`}
        />
      </RefreshCell>
      <RefreshCell all={1} lg={{ width: termAmoritizationPaymentFrequencyCellWidthDesktop }}>
        <FormSelectField id="termLength" label="Term length" value={state.termValue} options={state.termSelections} onChange={handleTermLengthChange} hiddenLabel={false} variant="secondary" size={inputSize} tooltipDescription="The term length is the desired amount of time you want to lock in your rate." data-testid="term-select-field" />
        <InsuredModeMessage message={state.termMessage} />
      </RefreshCell>
      <RefreshCell all={1} lg={{ width: termAmoritizationPaymentFrequencyCellWidthDesktop }}>
        <FormSelectField
          id="amortizationPeriod"
          label="Amortization period"
          onChange={handleAmortizationPeriodChange}
          options={state.amortizationSelections.map(months => ({ label: `${monthsToTimePeriodLabel(months)}s`, value: months }))}
          value={state.amortizationValue}
          hiddenLabel={false}
          variant="secondary"
          size={inputSize}
          tooltipDescription="The amortization period is the number of years it takes to pay off your mortgage in full. A shorter amortization period might mean higher monthly payments but lower overall interest."
          data-testid="amortization-select-field"
        />
        <InsuredModeMessage message={state.amortizationMessage} />
      </RefreshCell>
      <RefreshCell all={1} lg={{ width: termAmoritizationPaymentFrequencyCellWidthDesktop }}>
        <FormSelectField id="paymentFrequency" label="Payment frequency" onChange={handlePaymentFrequencyChange} options={PAYMENT_FREQUENCY_OPTIONS} hiddenLabel={false} variant="secondary" size={inputSize} tooltipDescription={`Payment frequency is how often you make payments towards your mortgage.\n\nThe more frequent your payments, the faster you'll pay off your principal and reduce your total interest over the lifespan of your mortgage.`} />
      </RefreshCell>
      <RefreshCell all={2} lg={{ width: 6, left: 1 }}>
        <div className={lowerInputLabel_}>
          <FormFieldLabel label="Client status" tooltipDescription={`Your client status is determined by how much you have with Wealthsimple (Core = $1+, Premium = $100,000+, Generation = $500,000+).\n\nIf you have a funded Wealthsimple account, you'll automatically receive a rebate based on your status. Deposit even more funds into your account, and you could upgrade your client status and rebate, too. [T&Cs apply](https://promotions.wealthsimple.com/hc/en-ca/articles/25292411730331).`} />
          <InputPercentCount label="Rebate" percentValue={formatTierRebate(state.clientStatusRebate, locale)} />
        </div>
        <TabSelector
          ref={tabSelectorClientStatusRef}
          tabs={CLIENT_STATUS_TAB_LABELS}
          size={inputSize}
          fullWidth={isTabSelectorFullWidth}
          value={capitalizeFirstLetter(state.tierLevel)}
          onChange={handleTierSelectorChange}
        />
      </RefreshCell>
      <RefreshCell all={2} lg={{ width: 6, left: 7 }}>
        <div className={lowerInputLabel_}>
          <FormFieldLabel
            label="Deposit amount"
            tooltipDescription={`For every $50,000 you deposit or transfer to Wealthsimple, you’ll receive an additional 0.05% rebate up to your quoted Pine mortgage rate.\n\nBringing more to Wealthsimple could also upgrade your client status, meaning you could increase your client rebate, too. [T&Cs apply](https://promotions.wealthsimple.com/hc/en-ca/articles/25292411730331).`}
          />
          <InputPercentCount label="Rebate" percentValue={formatTierRebate(state.depositRebate, locale)} />
        </div>
        <div className={mobileSliderValuePill_}><FluidText type="p" all="ws-text-sm-medium">{formatCurrency(state.depositValue)}</FluidText></div>
        <CalculatorSlider ref={depositSliderRef} value={state.depositValue} onChange={handleSliderChange} />
      </RefreshCell>
    </>
  );
};

CalculatorInputs.propTypes = {
  locale: PropTypes.oneOf(['en-ca', 'fr-ca']),
  inputSize: PropTypes.oneOf(['sm', 'lg']),
  isTabSelectorFullWidth: PropTypes.bool,
  // used for the theme transitions which is managed from parent
  termAmoritizationPaymentFrequencyCellWidthDesktop: PropTypes.number,
  tabSelectorMortgageTypeRef: PropTypes.shape().isRequired,
  tabSelectorClientStatusRef: PropTypes.shape().isRequired,
  depositSliderRef: PropTypes.shape().isRequired,
};
