import React from 'react';
import { asSubsection } from 'src/components/layout/Subsection';
import { MortgageRebateV2 } from '../MortgageRebateV2';
import { MortgageCalculatorProvider } from '../MortgageRebateV2/components/CalculatorProvider/CalculatorProvider.component';
import { calculatorMask_, calculatorInnerBackground_ } from './MortgageCalculator.module.scss';

export const MortgageCalculator = asSubsection('MortgageCalculator')(
  ({ heading, subheading, ...subsectionProps }) => {
    return (
      <div {...subsectionProps} className={calculatorMask_}>
        <div className={calculatorInnerBackground_} />
        <MortgageCalculatorProvider>
          <MortgageRebateV2 heading={heading} subheading={subheading} />
        </MortgageCalculatorProvider>
      </div>
    );
  }
);
