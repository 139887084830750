import React from 'react';
import PropTypes from 'prop-types';
import { GRID_DEFAULT_CONFIG, RefreshCell } from 'src/components/layout/Grid';
import { FluidText } from 'src/components/common/FluidText';
import { classNames } from 'src/utils/css';
import { headerCell_, cell_, cellText_, rowHeaderCellText_ } from './TableCell.module.scss';

// This is a single component for <th> and <td> because react-markdown does not
// distinguish between these two types of elements except as a prop (isHeader)
// which we ignore here, as TableRow takes care of that logic for us and
// provides this as the isColHeader and isRowHeader props.
export const TableCell = ({
  isColHeader,
  isRowHeader,
  isFirstCellInRow,
  numCols,
  className,
  annotation = {},
  children,
}) => {
  const isAnyHeader = isColHeader || isRowHeader;
  // In practice because we are using RefreshGrid, the number of columns must be
  // a divisor of 12. In other words: 2, 3, 4, or 6 columns only. That said, we
  // only really support 3 or 4 columns from a design POV.
  const cellConfig = {
    xs: Math.floor(GRID_DEFAULT_CONFIG.columns / (numCols - 1)),
    md: Math.floor(GRID_DEFAULT_CONFIG.columns / numCols),
  };
  if (isFirstCellInRow) cellConfig.xs = 12;

  let scope = '';
  if (isColHeader) scope = 'col';
  if (isRowHeader) scope = 'row';

  return (
    <RefreshCell
      as={isAnyHeader ? 'th' : 'td'}
      {...cellConfig}
      className={classNames(isAnyHeader ? headerCell_ : cell_, className)}
      scope={scope}
    >
      {isColHeader && isFirstCellInRow && <span className="sr-only">{children}</span>}
      {isColHeader && !isFirstCellInRow && (
        <FluidText type="div" all="ws-text-3xl-medium">
          {children}
        </FluidText>
      )}
      {isRowHeader && (
        <FluidText
          type="span"
          min="ws-text-sm-medium"
          max="ws-text-2xl-medium"
          className={rowHeaderCellText_}
        >
          {children}
        </FluidText>
      )}
      {!isColHeader && !isRowHeader && (
        <span className={cellText_}>
          <FluidText type="span" min="ws-text-sm" max="ws-text-2xl" {...annotation}>
            {children}
          </FluidText>
        </span>
      )}
    </RefreshCell>
  );
};

TableCell.propTypes = {
  isColHeader: PropTypes.bool, // set by TableRow
  isRowHeader: PropTypes.bool, // set by TableRow
  isFirstCellInRow: PropTypes.bool, // set by TableRow
  numCols: PropTypes.number,
};
