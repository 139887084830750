import React, { useLayoutEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import throttle from 'lodash/throttle';
import isChromatic from 'chromatic/isChromatic';
import { classNames, REFRESH_BREAKPOINTS } from 'src/utils/css';
import { formatCurrency } from 'src/utils/formatters';
import { useTranslationContext, useTranslation } from 'src/components/contexts/TranslationContext';
import { FluidText } from 'src/components/common/FluidText';
import { FluidContainer } from 'src/components/common/FluidContainer';
import { colorSlate95 } from 'src/styles/exports.module.scss';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { Markdown } from 'src/tapestry/core/markdown';
import { asSubsection } from 'src/components/layout/Subsection';
import { useComponentContext } from 'src/components/layout/ComponentContext';
import { toFieldPath } from '@stackbit/annotations';
import { translations } from './InvestGraph.translations';
import {
  graph_,
  mutualFundsPill_,
  mutualFundsPillInner_,
  mutualFundsColorKey_,
  wsPill_,
  wsPillInner_,
  wsColorKey_,
  graphHeading_,
  graphSubheading_,
  graphKeysContainer_,
  graphMfKey_,
  graphCircleKeyWs_,
  graphCircleKeyMf_,
  graphTimeline_,
  graphDisclosure_,
} from './InvestGraph.module.scss';

const FINAL_BALANCE_WS = 1221896;
const FINAL_BALANCE_MUTUAL_FUND = 947994;
const WS_KEY_COLOR = '#BDCF73';
const MF_KEY_COLOR = '#AFAAA7';
const GRADIENT_STOP_COLOR = colorSlate95;
const ORIGIN_PILL_PADDING = 6;
const BOUNDARIES = {
  width: 1223,
  height: 447,
  frame: 0.9695, // don't want to travel the full width of the SVG
};

const Graph = () => {
  const title = useTranslation('invest-graph::title');
  const description = useTranslation('invest-graph::description');
  const wrapperRef = useRef();
  const mutualFundsPillRef = useRef();
  const mutualFundsCurveRef = useRef();
  const wsPillRef = useRef();
  const wsCurveRef = useRef();
  const curveLengthsRef = useRef({ mutualFunds: 1, ws: 1 });
  const scaleRef = useRef({ width: 1, height: 1 });
  const intersectionObserverRef = useRef();

  const [wsValue, setWsValue] = useState(0);
  const [mfValue, setMfValue] = useState(0);
  const { locale } = useTranslationContext();

  const shouldRunAnimation =
    useMatchMedia(`(min-width: ${REFRESH_BREAKPOINTS.lg}px)`) && !isChromatic();

  const introAnimation = () => {
    const progress = { val: 0, ws: 0, mf: 0 };
    gsap.to(progress, {
      duration: 2,
      val: BOUNDARIES.frame,
      ws: FINAL_BALANCE_WS,
      mf: FINAL_BALANCE_MUTUAL_FUND,
      opacity: 1,
      ease: `power2.out`,
      onUpdate: () => {
        const prog = progress.val;
        const mutualFundsPoint = mutualFundsCurveRef.current.getPointAtLength(
          prog * curveLengthsRef.current.mutualFunds
        );
        gsap.set(mutualFundsPillRef.current, {
          opacity: progress.opacity,
          x: mutualFundsPoint.x * scaleRef.current.width,
          y: (mutualFundsPoint.y + ORIGIN_PILL_PADDING) * scaleRef.current.height,
        });
        const wsPoint = wsCurveRef.current.getPointAtLength(prog * curveLengthsRef.current.ws);
        gsap.set(wsPillRef.current, {
          opacity: progress.opacity,
          x: wsPoint.x * scaleRef.current.width,
          y: (wsPoint.y + ORIGIN_PILL_PADDING) * scaleRef.current.height,
        });

        gsap.set(wsPillRef.current, {
          opacity: progress.opacity,
          x: wsPoint.x * scaleRef.current.width,
          y: (wsPoint.y + ORIGIN_PILL_PADDING) * scaleRef.current.height,
        });

        setWsValue(Math.round(progress.ws));
        setMfValue(Math.round(progress.mf));
      },
    });
  };

  useLayoutEffect(() => {
    const resize = (progress = 1) => {
      // cache these for animation
      curveLengthsRef.current = {
        mutualFunds: mutualFundsCurveRef.current.getTotalLength(),
        ws: wsCurveRef.current.getTotalLength(),
      };
      let mutualFundsPoint = mutualFundsCurveRef.current.getPointAtLength(
        curveLengthsRef.current.mutualFunds
      );
      let wsPoint = wsCurveRef.current.getPointAtLength(curveLengthsRef.current.ws);
      let wrapperRect = wrapperRef.current.getBoundingClientRect();
      scaleRef.current = {
        width: wrapperRect.width / BOUNDARIES.width,
        height: wrapperRect.height / BOUNDARIES.height,
      };

      wrapperRect = wrapperRef.current.getBoundingClientRect();
      scaleRef.current = {
        width: wrapperRect.width / BOUNDARIES.width,
        height: wrapperRect.height / BOUNDARIES.height,
      };
      // position within the curve path - animates along SVG coordinates
      mutualFundsPoint = mutualFundsCurveRef.current.getPointAtLength(
        BOUNDARIES.frame * curveLengthsRef.current.mutualFunds
      );
      wsPoint = wsCurveRef.current.getPointAtLength(BOUNDARIES.frame * curveLengthsRef.current.ws);
      gsap.set(mutualFundsPillRef.current, {
        opacity: progress,
        x: mutualFundsPoint.x * scaleRef.current.width * progress,
        y: mutualFundsPoint.y * scaleRef.current.height * progress,
      });
      gsap.set(wsPillRef.current, {
        opacity: progress,
        x: wsPoint.x * scaleRef.current.width * progress,
        y: wsPoint.y * scaleRef.current.height * progress,
      });

      // animation cancel on mobile and Chromatic
      if (!shouldRunAnimation) {
        setWsValue(FINAL_BALANCE_WS);
        setMfValue(FINAL_BALANCE_MUTUAL_FUND);
      }
    };

    resize(shouldRunAnimation ? 0 : 1);
    const throttledResize = throttle(() => resize(1), 200);
    window.addEventListener('resize', throttledResize);

    if (shouldRunAnimation) {
      // intersection observer
      const options = { root: null, threshold: 1 };
      intersectionObserverRef.current = new IntersectionObserver(([entry]) => {
        if (entry && entry.isIntersecting && entry.intersectionRatio >= options.threshold) {
          introAnimation();
          intersectionObserverRef.current.unobserve(wrapperRef.current);
        }
      }, options);
      intersectionObserverRef.current.observe(wrapperRef.current);
    }

    return () => {
      window.removeEventListener('resize', throttledResize);
      if (intersectionObserverRef.current) intersectionObserverRef.current.disconnect();
    };
  }, []);
  return (
    <div ref={wrapperRef} className={graph_}>
      <div ref={mutualFundsPillRef} className={mutualFundsPill_}>
        <div className={mutualFundsPillInner_}>
          <FluidText type="p" min="ws-text-md" max="ws-text-2xl">
            {formatCurrency(mfValue, { locale })}
            <FluidContainer
              className={mutualFundsColorKey_}
              as="span"
              minWidthPx={10}
              maxWidthPx={20}
            />
          </FluidText>
        </div>
      </div>
      <div ref={wsPillRef} className={wsPill_}>
        <div className={wsPillInner_}>
          <FluidText type="p" min="ws-text-md" max="ws-text-2xl">
            {formatCurrency(wsValue, { locale })}
            <FluidContainer className={wsColorKey_} as="span" minWidthPx={10} maxWidthPx={20} />
          </FluidText>
        </div>
      </div>
      <svg
        role="img"
        aria-label={`${title} - ${description}`}
        width="100%"
        viewBox="0 0 1223 447"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{title}</title>
        <desc>{description}</desc>
        <g clipPath="url(#gradientBg)">
          <path
            d="M1172 436H6C589.5 375.687 1017.07 147.705 1172 45V436Z"
            fill="url(#paint0_linear_1_2)"
          />
          <path
            ref={mutualFundsCurveRef}
            d="M6 441C244.568 418.266 876.484 306.73 1217 165"
            stroke={MF_KEY_COLOR}
            strokeWidth="8.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            ref={wsCurveRef}
            d="M6 440.5C244.568 418.237 820.763 280.968 1217 24"
            stroke={WS_KEY_COLOR}
            strokeWidth="12"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1173 2V436"
            stroke="black"
            strokeWidth="4"
            strokeLinecap="round"
            strokeDasharray="1 12"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1_2"
            x1="601.468"
            y1="35.4655"
            x2="601.468"
            y2="424"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={WS_KEY_COLOR} />
            <stop offset="1" stopColor={GRADIENT_STOP_COLOR} />
          </linearGradient>
          <clipPath id="gradientBg">
            <rect width={BOUNDARIES.width} height={BOUNDARIES.height} fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

const InvestGraph = asSubsection('InvestGraph')(({ heading, subheading, ...rest }) => {
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);
  const { entryId } = useComponentContext();
  const headingAnnotation = entryId
    ? toFieldPath({ objectId: entryId, fieldPath: 'sectionHeading' })
    : {};
  const subheadingAnnotation = entryId
    ? toFieldPath({ objectId: entryId, fieldPath: 'sectionBody' })
    : {};

  return (
    <div {...rest} className={classNames('overflow-x:clip', rest.className)}>
      <FluidText
        className={graphHeading_}
        type="h2"
        min="ws-display-md-sans"
        max="ws-display-xl-sans"
        {...headingAnnotation}
      >
        {heading}
      </FluidText>
      <FluidText className={graphSubheading_} type="p" min="ws-text-lg" max="ws-text-2xl" {...subheadingAnnotation}>
        {subheading}
      </FluidText>
      <FluidText className={graphKeysContainer_} type="p" min="ws-text-sm" max="ws-text-2xl">
        <FluidContainer
          as="span"
          className={graphCircleKeyWs_}
          minWidthPx={10}
          maxWidthPx={20}
        />
        {useTranslation('invest-graph::label::ws')}
      </FluidText>
      <FluidText type="p" min="ws-text-sm" max="ws-text-2xl" className={graphMfKey_}>
        <FluidContainer
          className={graphCircleKeyMf_}
          as="span"
          minWidthPx={10}
          maxWidthPx={20}
        />
        {useTranslation('invest-graph::label::mf')}
      </FluidText>
      <Graph
        title={useTranslation('invest-graph::title')}
        description={useTranslation('invest-graph::description')}
      />
      <div className={graphTimeline_}>
        <FluidText type="p" min="ws-text-sm" max="ws-text-2xl">
          {useTranslation('invest-graph::timeline::label::start')}
        </FluidText>
        <FluidText type="p" min="ws-text-sm" max="ws-text-2xl">
          {useTranslation('invest-graph::timeline::label::end')}
        </FluidText>
      </div>
      <FluidText type="div" all="ws-text-xs" className={graphDisclosure_}>
        <Markdown source={useTranslation('invest-graph::disclosure')} />
      </FluidText>
    </div>);
});

export { InvestGraph };
