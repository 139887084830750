import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { CardsWithFilter } from 'src/components/common/CardsWithFilter';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { asSubsection } from 'src/components/layout/Subsection';
import { useGetAccountLink, sortAccountsByPreferredOrder } from 'src/utils/accounts-helpers';

export const FilterableAccountCards = asSubsection('FilterableAccountCards')(({ accountDetails = [], ...rest }) => {
  const { getStringWithGlobals, isFr } = useTranslationContext();
  const getAccountLink = useGetAccountLink();
  const accountCardsOrderBySlug = [
    'rrsp',
    'tfsa',
    'lira',
    'resp',
    'fhsa',
    'non-registered',
    'business-invest',
    'spousal-rrsp',
  ];

  // This is a mapping of the selection of Contentful account categories to the French version of the category
  const accountCategoryTranslations = {
    'Retirement': 'Retraite',
    'Education': 'Études',
    'Home': 'Maison',
    'All': 'Tout',
  };
  const allCategoryForCurrentLang = isFr ? accountCategoryTranslations.All : 'All';

  const accountsCardsData = sortAccountsByPreferredOrder(accountDetails, accountCardsOrderBySlug).map((account => {
    const { accountShortName,
      accountName,
      accountDescription,
      accountKeyDetail1,
      accountKeyDetail2,
      urlSlug,
      accountPageOverride,
      accountCategories } = account;

    const accountCategoriesForCurrentLang = isFr ? accountCategories?.map(category => accountCategoryTranslations[category]) : accountCategories;

    return { heading: accountShortName ?? accountName,
      headingSupport: accountShortName ? accountName : null,
      subheading: getStringWithGlobals(accountDescription),
      list: [getStringWithGlobals(accountKeyDetail1), getStringWithGlobals(accountKeyDetail2)],
      link: getAccountLink({ urlSlug, accountPageOverride }),
      tags: [...accountCategoriesForCurrentLang ?? [], allCategoryForCurrentLang] };
  }));

  // We don't want categories to show in the filter that don't have any accounts associated with them
  const setAccountCategories = useCallback(() => {
    const accountCategories = Object.keys(accountCategoryTranslations).filter(category => {
      return accountDetails.some(account => account?.accountCategories?.includes(category)) || category === 'All';
    });

    return isFr ? accountCategories.map(category => accountCategoryTranslations[category]) : accountCategories;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div {...rest}>
      <CardsWithFilter
        tabData={setAccountCategories()}
        cardData={accountsCardsData}
      />
    </div>);
});

FilterableAccountCards.propTypes = {
  accountDetails: PropTypes.arrayOf(PropTypes.shape({
    accountName: PropTypes.string,
    accountShortName: PropTypes.string,
    accountDescription: PropTypes.string,
    urlSlug: PropTypes.string,
    accountPageOverride: PropTypes.shape({
      slug: PropTypes.string,
      template: PropTypes.string,
    }),
    accountKeyDetail1: PropTypes.string,
    accountKeyDetail2: PropTypes.string,
    accountCategories: PropTypes.arrayOf(PropTypes.string),
  })),
};
