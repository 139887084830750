import React from 'react';
import { classNames } from 'src/utils/css';
import { Background } from 'src/components/common/Background';
import { useTranslation, useTranslationContext } from 'src/components/contexts/TranslationContext';
import { TIER_NAMES } from 'src/utils/tiers';
import { translations } from './Eyebrow.translations';
import { eyebrow_ } from './Eyebrow.module.scss';
import { TIERS_EYEBROWS } from './Eyebrow.config';

export const Eyebrow = ({ children, background, className, ...rest }) => {
  return <Background as="p" background={background} className={classNames(eyebrow_, 'ws-eyebrow-sm', className)} {...rest}>{children}</Background>;
};

export const TierEyebrow = ({ tierName = TIER_NAMES.CORE }) => {
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);
  const { background, translationKey } = TIERS_EYEBROWS[tierName];
  return <Eyebrow key={translationKey} background={background}>{useTranslation(translationKey)}</Eyebrow>;
};
