import React from 'react';
import PropTypes from 'prop-types';
import { Subsection } from 'src/components/layout/Subsection';
import { logoShowcase_, logoContainer_ } from './LogoShowcase.module.scss';

const LogoShowcase = ({ logos, ...props }) => {
  return (
    <Subsection subsectionName="LogoShowcase" {...props}>
      <div className={logoShowcase_}>
        {logos.map(({ id, asset }) => (
          <div className={logoContainer_} key={id}>
            {asset}
          </div>
        ))}
      </div>
    </Subsection>
  );
};

LogoShowcase.propTypes = {
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      asset: PropTypes.node,
    })
  ),
};

export { LogoShowcase };
